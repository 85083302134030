import { prop } from 'ramda';
import { createSelector } from 'reselect';
import {
  splitConversationId, isAdmin,
  CONVERSATION_TYPE, CONVERSATION_EXPOSURE,
} from '@twnel/utils-js/lib/web';
import { getSelectedCompanyId, getUserAgentId, getUserAgent } from '@twnel/companies-login';
import { getUserInfo, getConversationsArray } from 'src/data/selectors';

const {
  AGENT, BUSINESS, CUSTOMER, GROUP,
} = CONVERSATION_TYPE;
const { PENDING, ACTIVE } = CONVERSATION_EXPOSURE;

export const getOnlineSince = createSelector(
  getUserInfo,
  prop('online'),
);

export const getAutolockig = createSelector(
  getUserInfo,
  ({ autolocking }) => !!autolocking,
);

export const getShouldRefresh = createSelector(
  getSelectedCompanyId,
  getUserInfo,
  (companyId, { conversationsUpToDate }) => companyId !== conversationsUpToDate,
);

export const getChatConversations = createSelector(
  getUserAgentId,
  getConversationsArray(
    getSelectedCompanyId,
    ({ type, exposure, badge }) => {
      if (type !== CUSTOMER && type !== AGENT) {
        return false;
      }
      return exposure === PENDING || (exposure === ACTIVE && badge);
    },
  ),
  (userAgentId, conversations) => conversations.filter((conversation) => {
    if (conversation.type === AGENT) {
      const { id, owner, resource } = splitConversationId(conversation);
      return id !== owner && resource === userAgentId;
    }
    return conversation.exposure === PENDING
      || conversation.agent?.id === userAgentId;
  }),
);

export const getGroupConversations = createSelector(
  getSelectedCompanyId,
  getUserAgentId,
  getConversationsArray(
    getSelectedCompanyId,
    ({ type, badge }) => {
      if (type !== GROUP && type !== BUSINESS && type !== AGENT) {
        return false;
      }
      return !!badge;
    },
  ),
  (selectedCompanyId, userAgentId, conversations) => conversations.filter((conversation) => {
    if (conversation.type === AGENT) {
      const { id, resource } = splitConversationId(conversation);
      return id === selectedCompanyId && resource === userAgentId;
    }
    return true;
  }),
);

export const getUserIsAdmin = createSelector(
  getUserAgent,
  isAdmin,
);
