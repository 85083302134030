import {
  identity, assoc, prop, compose, toPairs, fromPairs, slice, sort, reduce,
} from 'ramda';
import { createSelector } from 'reselect';
import { throwNetworkError } from '@twnel/web-components';
import { isGroup, sortMessages, CONVERSATION_TYPE } from '@twnel/utils-js/lib/web';
import {
  getSelectedCompanyId, getUserAgentId, getUserAgent, getCompany,
} from '@twnel/companies-login';
import {
  getConversation, getConversationsArray, getLastMessage, getLastReadMessage,
} from 'src/data/selectors';
import { updateConversations } from '../basic';
import { postAgent } from '../agents';

const {
  AGENT, BUSINESS, CUSTOMER,
} = CONVERSATION_TYPE;

const READ_STAMPS = {
  MAX: 80,
  OFFSET: 2000,
};

const cleanUserAgentStamps = createSelector(
  prop('readstamps'),
  compose(
    reduce((result, [id, stamp]) => {
      const match = /^chat_(.+)/i.exec(id);
      if (match) {
        const cleanId = decodeURIComponent(match[1]);
        return { ...result, [cleanId]: stamp };
      }
      return result;
    }, {}),
    toPairs,
  ),
);

const updateChatUnreadCount = (conversationId) => (dispatch, getState) => {
  const companyId = getSelectedCompanyId(getState());
  const chats = conversationId ? getConversation(
    companyId,
    conversationId,
    getState(),
  ) : getConversationsArray(
    companyId,
    ({ type }) => type === BUSINESS || type === AGENT || type === CUSTOMER,
    getState(),
  );

  const userAgent = getUserAgent(getState());
  const cleanStamps = cleanUserAgentStamps(userAgent);
  const updateChat = (chat, flexible = false) => {
    if (!chat) {
      return null;
    }
    const lastMessage = getLastMessage(companyId, chat.id, getState());
    const badge = (
      lastMessage?.author !== userAgent.id
      || lastMessage?.companyId !== companyId
    ) && (
      (cleanStamps[chat.id] === undefined && flexible)
      || lastMessage?.date > cleanStamps[chat.id]
    ) ? true : undefined;
    if (badge === chat.badge) {
      return null;
    }
    return assoc('badge', badge, chat);
  };

  const updatedChats = chats instanceof Array ? chats.reduce((result, chat) => {
    const update = updateChat(chat);
    return update ? [...result, update] : result;
  }, []) : [updateChat(chats, true)].filter(identity);
  dispatch(updateConversations(companyId, updatedChats));
};

const updateGroupUnreadCount = () => async (dispatch, getState, getContext) => {
  const { api } = getContext();
  const company = getCompany(getSelectedCompanyId, getState());
  const timestamps = await api.messages.getUnreadCount(getUserAgent(getState()), company)
    .catch(throwNetworkError);

  const readstamps = getUserAgent(getState()).readstamps || {};
  const stamps = Object.keys(timestamps).reduce((accumulator, stampId) => {
    const { room_id: groupId, unread } = timestamps[stampId];
    if (readstamps[stampId]) {
      accumulator[groupId] = /^all$/i.test(unread) ? Number.MAX_SAFE_INTEGER : unread;
    } else {
      accumulator[groupId] = Number.MAX_SAFE_INTEGER;
    }
    return accumulator;
  }, {});

  const groups = getConversationsArray(
    company.id,
    isGroup,
    getState(),
  );
  const updatedGroups = groups.reduce((result, group) => {
    if (stamps[group.id] !== group.badge) {
      return [...result, assoc('badge', stamps[group.id], group)];
    }
    return result;
  }, []);
  dispatch(updateConversations(company.id, updatedGroups));
};

export const updateUnreadCounts = ({ conversationId, messages } = {}) => (dispatch, getState) => {
  if (!conversationId) {
    dispatch(updateChatUnreadCount());
    dispatch(updateGroupUnreadCount());
    return;
  }

  const companyId = getSelectedCompanyId(getState());
  const conversation = getConversation(companyId, conversationId, getState());
  if (isGroup(conversation)) {
    let badgeValue;
    const userAgentId = getUserAgentId(getState());
    const badgeCount = sortMessages(messages).reduce((count, message) => {
      if (message.companyId === companyId && message.author === userAgentId) {
        return 0;
      }
      return count + 1;
    }, 0);
    if (!badgeCount) {
      badgeValue = 0;
    } else {
      badgeValue = conversation.badge === Number.MAX_SAFE_INTEGER
        ? conversation.badge
        : (conversation.badge ?? 0) + badgeCount;
    }
    dispatch(updateConversations(companyId, [assoc('badge', badgeValue, conversation)]));
  } else if (conversation) {
    dispatch(updateChatUnreadCount(conversationId));
  }
};

export const markAgentReadStamp = (conversationId) => (dispatch, getState) => {
  const companyId = getSelectedCompanyId(getState());
  const conversation = getConversation(companyId, conversationId, getState());
  if (!conversation) {
    return;
  }

  const id = isGroup(conversation)
    ? `room_${conversation.id.replace(/-/g, '_')}`
    : `chat_${encodeURIComponent(conversation.id)}`;

  const agent = getUserAgent(getState());
  const lastStamp = agent.readstamps?.[id];

  const lastMessage = (
    isGroup(conversation) ? getLastMessage : getLastReadMessage
  )(companyId, conversationId, getState());
  const newStamp = lastMessage?.date || Date.now();
  if ((lastStamp && !lastMessage) || newStamp <= lastStamp) {
    return;
  }

  let newStamps = {
    ...(agent.readstamps || {}),
    [id]: newStamp + READ_STAMPS.OFFSET,
  };
  if (Object.keys(newStamps).length > READ_STAMPS.MAX) {
    newStamps = compose(
      fromPairs,
      slice(0, READ_STAMPS.MAX * 0.75),
      sort(([, stampA], [, stampB]) => stampB - stampA),
      toPairs,
    )(newStamps);
  }
  dispatch(postAgent(
    assoc('readstamps', newStamps, agent),
    { propagate: false },
  ));
};
