import { compose, prop } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, contrastColor, useTheme } from '@twnel/web-components';

const HEIGHT = 60;

const SearchBar = ({ placeholder, onQuery }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: `${HEIGHT}px`,
        padding: '0 0.5rem',
        boxSizing: 'border-box',
        borderBottom: `thin solid ${contrastColor(4, theme.background)}`,
      }}
    >
      <div
        style={{
          opacity: 0.8,
          padding: '0 0.75rem 0 0.25rem',
        }}
      >
        <i className="fas fa-search" />
      </div>
      <TextInput
        boxed={false}
        placeholder={placeholder}
        onChange={compose(onQuery, prop('value'))}
      />
    </div>
  );
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onQuery: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
  placeholder: '',
};

SearchBar.HEIGHT = HEIGHT;

export default SearchBar;
