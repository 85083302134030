import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, keyPress } from '@twnel/web-components';

const Modal = ({
  width, title, children, onClose,
}) => (children ? (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 50,
      width: '100%',
      height: '100%',
    }}
  >
    <div style={{ flexGrow: 1 }} />
    <Box
      style={{
        position: 'relative',
        zIndex: 5,
        width: `${width}rem`,
        padding: '1.25rem 1.50rem',
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          marginBottom: '0.3125rem',
          width: `calc(${width}rem - 2.5rem)`,
        }}
      >
        <span
          style={{
            flexGrow: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '1.5rem',
            fontWeight: 600,
          }}
        >
          {title}
        </span>
        <IconButton
          onClick={onClose}
          style={{ transformOrigin: '100% 0%' }}
        >
          <i className="fas fa-times" />
        </IconButton>
      </div>
      {children}
    </Box>
    <div style={{ flexGrow: 1.5 }} />
    <div
      role="button"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
        outline: 'none',
        opacity: 0.5,
      }}
      onClick={onClose}
      onKeyPress={keyPress(onClose)}
      tabIndex={0}
      aria-label="Close"
    />
  </div>
) : null);

Modal.propTypes = {
  width: PropTypes.number,
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  width: 27,
  title: '',
  children: null,
};

export default Modal;
