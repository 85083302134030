import {
  contains, identity, assoc, prop, compose, flatten,
} from 'ramda';
import { batch } from 'react-redux';
import { throwNetworkError } from '@twnel/web-components';
import { uploadS3 } from '@twnel/utils-js/lib/aws';
import { isGroup } from '@twnel/utils-js/lib/web';
import {
  getAWS, getSelectedCompanyId, getUserAgent, getCompany,
} from '@twnel/companies-login';
import { getUserInfo, getConversation, getConversationsArray } from 'src/data/selectors';
import { updateConversations, updateUserInfo } from '../basic';
import { fetchConversationsOrigin } from './contacts';
import { archiveConversation, cleanAndFilter, ARCHIVE } from './util';

const checkRemovedConversations = (newConversations) => (dispatch, getState) => {
  const companyId = getSelectedCompanyId(getState());
  const ids = newConversations.map(prop('id'));
  const removedConversations = getConversationsArray(companyId, identity, getState())
    .filter(compose((id) => !contains(id, ids), prop('id')))
    .map(archiveConversation(getState()));
  dispatch(updateConversations(companyId, removedConversations));
};

export const loadConversations = () => async (dispatch, getState, getContext) => {
  const company = getCompany(getSelectedCompanyId, getState());
  const { conversationsUpToDate } = getUserInfo(getState());
  if (conversationsUpToDate === company.id) {
    return;
  }

  const { api } = getContext();
  const agent = getUserAgent(getState());
  const options = { agent, worldwide: !!company.worldwide };
  const loadTasks = [
    api.groups.get(),
    api.conversations.getAll(options),
    api.conversations.getAll({
      ...options,
      allAgents: true,
    }),
    api.conversations.getAll({
      ...options,
      archived: true,
      after: Date.now() - ARCHIVE.HISTORY,
      limit: ARCHIVE.LIMIT,
    }),
  ];

  const conversations = await Promise.all(loadTasks)
    .catch(throwNetworkError)
    .then(compose(dispatch, fetchConversationsOrigin, flatten))
    .then(cleanAndFilter(getState()));

  batch(() => {
    dispatch(updateConversations(company.id, conversations));
    dispatch(checkRemovedConversations(conversations));
    dispatch(updateUserInfo({ conversationsUpToDate: company.id }));
  });
};

export const outdateConversations = () => (dispatch) => {
  dispatch(updateUserInfo({ conversationsUpToDate: null }));
};

export const postConversation = (conversation) => async (dispatch, getState, getContext) => {
  const companyId = getSelectedCompanyId(getState());
  const oldConversation = getConversation(companyId, conversation.id, getState());

  let requests;
  const { api } = getContext();
  if (isGroup(conversation) && oldConversation) {
    dispatch(updateConversations(companyId, [conversation]));
    requests = [
      api.groups.update,
      updateConversations(companyId, [oldConversation]),
    ];
  } else if (isGroup(conversation)) {
    requests = [async (instance) => {
      const group = await api.groups.create(instance);
      dispatch(updateConversations(companyId, [group]));
    }];
  } else if (oldConversation) {
    dispatch(updateConversations(companyId, [conversation]));
    requests = [
      (instance) => api.conversations.update([instance]),
      updateConversations(companyId, [oldConversation]),
    ];
  } else {
    requests = [(instance) => api.conversations.update([instance])];
  }
  const [post, undo] = requests;
  try {
    await post(conversation);
  } catch (error) {
    if (undo) {
      dispatch(undo);
    }
    throwNetworkError(error);
  }
};

export const updateGroupImage = (group, file) => async (dispatch, getState, getContext) => {
  const { id, image: oldImage } = group;
  const companyId = getSelectedCompanyId(getState());
  const tempImage = URL.createObjectURL(file);
  dispatch(updateConversations(companyId, [assoc('image', tempImage, group)]));

  let result;
  const { api } = getContext();
  const aws = getAWS(getState());
  const getGroup = () => getConversation(companyId, id, getState());
  try {
    const { url } = await uploadS3({ aws, file });
    await api.groups.update(assoc('image', url, getGroup()));
    result = url;
  } catch (error) {
    result = oldImage;
  }

  URL.revokeObjectURL(tempImage);
  dispatch(updateConversations(companyId, [
    assoc('image', result, getGroup()),
  ]));
};

export const deleteConversation = (conversation) => async (dispatch, getState, getContext) => {
  let requests;
  const { api } = getContext();
  if (isGroup(conversation)) {
    const companyId = getSelectedCompanyId(getState());
    const oldConversation = getConversation(companyId, conversation.id, getState());
    dispatch(updateConversations(companyId, [
      archiveConversation(getState(), conversation, { remove: true }),
    ]));
    requests = [
      api.groups.delete,
      updateConversations(companyId, [oldConversation]),
    ];
  } else {
    requests = [(instance) => api.conversations.remove([instance])];
  }
  const [remove, undo] = requests;
  try {
    await remove(conversation);
  } catch (error) {
    if (undo) {
      dispatch(undo);
    }
    throwNetworkError(error);
  }
};
