import { curry } from 'ramda';
import { getTexts } from '@twnel/web-components';
import { encodeId, splitConversationId, CONVERSATION_TYPE } from '@twnel/utils-js/lib/web';
import { getSelectedCompanyId } from '@twnel/companies-login';
import { updateConversations, loadMessageHistory, deleteMessages } from 'src/data/actions';
import { getConversation, getAllMessages } from 'src/data/selectors';
import { conversationAvatar, messageAvatar, messageDescription } from 'src/data/util';

const { AGENT, BUSINESS, GROUP } = CONVERSATION_TYPE;

export const downloadMessageHistory = (conversationId) => async (dispatch, getState) => {
  await dispatch(loadMessageHistory(conversationId));

  const texts = getTexts(getState());
  const messages = getAllMessages(getSelectedCompanyId, conversationId, getState());
  const history = messages.reduce((result, message) => {
    const { id, name, companyId } = messageAvatar(message, getState());
    const date = new Date(message.date).toISOString();
    const body = messageDescription(
      message,
      `${name} (${companyId ? `${companyId}-` : ''}${id})`,
      texts,
    );
    return `${result}${date}\t${body}\n`;
  }, '');

  const conversation = getConversation(getSelectedCompanyId, conversationId, getState());
  const { id, name, companyId } = conversationAvatar(conversation, getState());
  const filename = `${name}_${id}${companyId ? `_${companyId}` : ''}`;
  const blob = new Blob([history], { type: 'text/plain;charset=utf-8' });

  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = `${filename.replace(/\s/g, '_')}.txt`;
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }, 0);
};

export const messageClick = (showFullscreenContent) => (type, ...content) => {
  if (type === 'image') {
    const [src, name] = content;
    showFullscreenContent({ src, name });
  }
};

export const openChat = curry((history, conversation) => (dispatch, getState) => {
  const { id, type } = conversation;
  const selectedCompanyId = getSelectedCompanyId(getState());
  if (!getConversation(selectedCompanyId, id, getState())) {
    dispatch(updateConversations(selectedCompanyId, [conversation]));
  }

  let section;
  if (type === AGENT) {
    const { id: companyId } = splitConversationId(conversation);
    section = selectedCompanyId === companyId ? 'channels' : 'chats';
  } else {
    section = type === GROUP || type === BUSINESS ? 'channels' : 'chats';
  }
  history.replace(`/${section}/${encodeId(id)}`);
});

export const deleteMessage = curry((conversationId, message) => (dispatch, getState) => {
  const selectedCompanyId = getSelectedCompanyId(getState());
  dispatch(deleteMessages(selectedCompanyId, conversationId, [message]));
});
