import to from 'await-to-js';
import { flip, compose } from 'ramda';
import { batch } from 'react-redux';
import { networkError } from '@twnel/web-components';
import {
  getUserAgentId, getSelectedCompanyId, getCompany, getAgent, updateAgent,
} from '@twnel/companies-login';
import { parseApiAgent, AGENT_ROLE } from '@twnel/utils-js/lib/web';
import { getUserInfo, getIsAutolockerEnabled } from 'src/data/selectors';
import { USER_STATUS } from 'src/data/constants';
import { updateUserInfo } from './basic';

const { DELETED } = AGENT_ROLE;
const { AVAILABLE } = USER_STATUS;

const removeAgent = ({ phoneNumber }) => ({
  phoneNumber,
  role: DELETED,
  tags: [],
});

export const postAgent = (
  agent,
  { propagate = true } = {},
) => async (dispatch, getState, getContext) => {
  const company = getCompany(getSelectedCompanyId, getState());
  const oldAgent = getAgent(getSelectedCompanyId, agent.id, getState());
  dispatch(updateAgent(company, agent));

  const { api } = getContext();
  const request = oldAgent
    ? () => api.agents.update([agent], { propagate })
    : () => api.agents.create([agent]);
  try {
    await request();
  } catch (error) {
    dispatch(updateAgent(company, oldAgent || removeAgent(agent)));
    throw networkError(error);
  }
};

export const deleteAgents = (agentIds) => async (dispatch, getState, getContext) => {
  const company = getCompany(getSelectedCompanyId, getState());
  const agents = agentIds.map(flip(getAgent(company.id)));
  batch(() => {
    agents.forEach(compose(
      dispatch,
      updateAgent(company),
      removeAgent,
    ));
  });

  const { api } = getContext();
  try {
    await api.agents.remove(agents);
  } catch (error) {
    agents.forEach(compose(
      dispatch,
      updateAgent(company),
    ));
    throw networkError(error);
  }
};

const parseRawAgents = (payload) => {
  if (payload instanceof Array) {
    return payload.reduce((result, rawAgent) => {
      try {
        const agent = parseApiAgent(rawAgent);
        return [...result, agent];
      } catch (error) {
        return result;
      }
    }, []);
  }
  return payload?.id?.length ? [payload] : [];
};

export const onAgentUpdate = ({ event, payload }) => (dispatch, getState) => {
  switch (event) {
    case 'create':
    case 'update': {
      const company = getCompany(getSelectedCompanyId, getState());
      parseRawAgents(payload).forEach(compose(
        dispatch,
        updateAgent(company),
      ));
      break;
    }
    case 'delete': {
      const company = getCompany(getSelectedCompanyId, getState());
      parseRawAgents(payload).forEach(compose(
        dispatch,
        updateAgent(company),
        removeAgent,
      ));
      break;
    }
    default:
      break;
  }
};

export const onAgentAutolockerUpdate = ({ event, payload }) => (dispatch, getState) => {
  if (!getIsAutolockerEnabled(getState())) {
    // Only accept availability updates if the autolocker is enabled
    return;
  }
  dispatch(onAgentUpdate({ event, payload }));
};

export const updateAvailability = ({
  status = AVAILABLE,
  tags = [],
} = {}) => async (dispatch, getState, getContext) => {
  if (!getIsAutolockerEnabled(getState())) {
    // Only report availability if the autolocker is enabled
    return;
  }

  const userId = getUserAgentId(getState());
  const selectedCompanyId = getSelectedCompanyId(getState());
  const payload = {
    company: selectedCompanyId,
    agent: userId,
    presence: 'ONLINE',
    status,
    tags,
  };

  const timestamp = Date.now();
  dispatch(updateUserInfo({ autolocking: -timestamp }));

  const { socket } = getContext();
  const [error] = await to(socket.emit('availability', payload));

  const { autolocking } = getUserInfo(getState());
  if (autolocking + timestamp === 0) {
    dispatch(updateUserInfo({
      autolocking: error ? 0 : timestamp,
    }));
  }
};
