import React from 'react';
import {
  identity, omit, thunkify, equals, reduce, compose, toPairs, complement,
} from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { Loader, debounce } from '@twnel/web-components';
import { getUserAgent } from '@twnel/companies-login';
import { updateUserConfig, updateAvailability } from 'src/data/actions';
import { getUserConfig, getIsAutolockerEnabled } from 'src/data/selectors';
import { USER_STATUS } from 'src/data/constants';
import { Splash, useIntervalFilter, getAutolockerFilter } from 'src/ui/util';
import {
  getChatConversations, getGroupConversations, getUserIsAdmin, getOnlineSince, getAutolockig,
} from './selectors';

const { AVAILABLE, AWAY } = USER_STATUS;

const splashThunk = (column) => thunkify(Splash)({ column });

const sections = [
  {
    path: '/chats',
    icon: 'fas fa-comments fa-fw',
    alt: 'Chats',
    loader: () => import(
      /* webpackChunkName: "chats" */
      'src/ui/Chats'
    ),
    Fallback: splashThunk({ width: 380 }),
    badgeConversations: getChatConversations,
    badgeFilter: createSelector(
      getAutolockerFilter,
      complement,
    ),
  },
  {
    path: '/dashboard',
    icon: 'fas fa-th-list',
    alt: 'Dashboard',
    loader: () => import(
      /* webpackChunkName: "dashboard" */
      'src/ui/Dashboard'
    ),
    Fallback: splashThunk({ width: 340, position: 'right' }),
  },
  {
    path: '/channels',
    icon: 'fas fa-users fa-fw',
    alt: 'Channels',
    loader: () => import(
      /* webpackChunkName: "groups" */
      'src/ui/Groups'
    ),
    Fallback: splashThunk({ width: 380 }),
    badgeConversations: getGroupConversations,
  },
  {
    path: '/broadcasts',
    icon: 'fas fa-bullhorn fa-fw',
    alt: 'Broadcast',
    adminOnly: true,
    loader: () => import(
      /* webpackChunkName: "broadcasts" */
      'src/ui/Broadcasts'
    ),
    Fallback: splashThunk({ width: 380 }),
  },
  {
    path: '/contacts',
    icon: 'fas fa-address-book fa-fw',
    alt: 'Contacts',
    loader: () => import(
      /* webpackChunkName: "contacts" */
      'src/ui/Contacts'
    ),
    Fallback: splashThunk({ width: 340, position: 'right' }),
  },
];

/**
 * Reduce all the sections property for the given key into a map
 * @param { list: [String, Any], key: String }
 * @return [String: Any]
 */
const reduceForKey = ({ list = {}, key = '' }) => list.reduce((result, section) => {
  if (!section[key]) {
    return result;
  }
  return { ...result, [section.path]: section[key] };
}, {});

/**
 * Take a map of keyed lists, and return a flattened array of pairs
 * @param [String: Object] map
 * @return [[String, Object]]
 */
const toFlatPairs = compose(
  reduce((result, [key, list]) => [
    ...result,
    ...list.map((value) => [key, value]),
  ], []),
  toPairs,
);

/**
 * Take a flattened array of pairs, and return a map with the number of
 * values for each key
 * @param [[String, Object]] list of pairs
 * @return [String: Int]
 */
const countFlatPairs = reduce((result, [key]) => ({
  ...result,
  [key]: (result[key] ?? 0) + 1,
}), {});

const useFilteredBadges = ({ conversations, filters }) => {
  const autolockEnabled = useSelector(getIsAutolockerEnabled);
  const flatPairs = React.useMemo(
    () => toFlatPairs(conversations),
    [conversations],
  );
  const filteredPairs = useIntervalFilter({
    list: flatPairs,
    filter: autolockEnabled ? ([path, conversation]) => (
      !filters[path] || filters[path](conversation)
    ) : identity,
  });
  return countFlatPairs(filteredPairs);
};

export const makeUseSections = (store) => {
  const componentLoader = Loader(store);
  const appSections = sections.map((section) => {
    const { loader } = section;
    const cleanSection = omit(['loader'], section);
    cleanSection.Component = componentLoader(loader);
    return cleanSection;
  });

  const badgeConversationsSelector = createStructuredSelector(reduceForKey({
    list: appSections,
    key: 'badgeConversations',
  }));

  const badgeFiltersSelector = createStructuredSelector(reduceForKey({
    list: appSections,
    key: 'badgeFilter',
  }));

  return () => {
    const admin = useSelector(getUserIsAdmin);
    const conversations = useSelector(badgeConversationsSelector);
    const filters = useSelector(badgeFiltersSelector);
    const badges = useFilteredBadges({ conversations, filters });
    return appSections.reduce((list, section) => {
      if (!admin && section.adminOnly) {
        return list;
      }
      const cleanSection = omit(['badgeConversations', 'adminOnly'], section);
      if (badges[cleanSection.path]) {
        cleanSection.badge = badges[cleanSection.path];
      }
      return [...list, cleanSection];
    }, []);
  };
};

export const useTabBadge = (parsedSections) => {
  const badgeCount = React.useMemo(() => parsedSections?.reduce?.(
    (result, { badge }) => result + (badge ?? 0),
    0,
  ) ?? 0, [parsedSections]);
  React.useEffect(() => {
    const link = document.querySelector("link[rel~='icon']");
    link.href = badgeCount
      ? 'https://twnelpublic.s3.us-west-2.amazonaws.com/favicon/chatManagerBadge/favicon-32x32.png'
      : 'https://s3-us-west-2.amazonaws.com/twnelpublic/favicon/chatManager/favicon-32x32.png';
    document.title = badgeCount ? `(${badgeCount}) Twnel App` : 'Twnel App';
  }, [badgeCount]);
};

export const useOnlineStatus = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUserAgent);
  const { status: userStatus } = useSelector(getUserConfig);
  const onlineSince = useSelector(getOnlineSince);
  const autolockig = useSelector(getAutolockig);
  const autolockEnabled = useSelector(getIsAutolockerEnabled);

  const dispatchUpdate = React.useMemo(() => debounce((update) => {
    dispatch(updateAvailability(update));
  }, 50), [dispatch]);

  const tags = React.useRef(null);
  if (!equals(tags.current, user?.tags)) {
    tags.current = user?.tags;
  }

  const agentTags = tags.current;
  React.useEffect(() => {
    if (!autolockEnabled || !agentTags || onlineSince <= 0) {
      return;
    }
    dispatchUpdate({ status: userStatus, tags: agentTags });
  }, [dispatchUpdate, agentTags, onlineSince, userStatus, autolockEnabled]);

  const toggleOnline = React.useMemo(() => (autolockEnabled ? () => {
    const newStatus = userStatus !== AVAILABLE ? AVAILABLE : AWAY;
    dispatch(updateUserConfig({ status: newStatus }));
  } : undefined), [dispatch, userStatus, autolockEnabled]);

  return autolockEnabled ? {
    onlineSince,
    toggleOnline,
    available: userStatus !== AWAY,
    autolockig,
  } : { onlineSince };
};

const checkDarkMode = () => (
  window.matchMedia?.('(prefers-color-scheme: dark)')?.matches ?? false
);

export const useDarkMode = () => {
  const dispatch = useDispatch();
  const { darkMode: userPreference } = useSelector(getUserConfig);
  const darkMode = userPreference ?? checkDarkMode();
  const toggleDarkMode = React.useCallback(() => {
    dispatch(updateUserConfig({ darkMode: !darkMode }));
  }, [dispatch, darkMode]);
  return [darkMode, toggleDarkMode];
};
