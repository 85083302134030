import {
  compose, flip, equals, prop,
} from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  MessagesList, tryUntilResolve, rejectClientErrors, getTexts, getFont,
} from '@twnel/web-components';
import {
  encodeId, decodeId, splitConversationId, CONVERSATION_TYPE,
} from '@twnel/utils-js/lib/web';
import { getSelectedCompanyId } from '@twnel/companies-login';
import {
  loadContacts, loadContactData, updateUnreadCounts, refreshMessages,
  loadMessagesPage, markAgentReadStamp,
} from 'src/data/actions';
import {
  getConversationsById, getTopMessage, getLastMessage, getMessagesInfo,
} from 'src/data/selectors';
import { parseMessage, conversationAvatar, tagsToId } from 'src/data/util';
import { getTemplates } from '../selectors';

const { AGENT, GROUP } = CONVERSATION_TYPE;

export const useOpenConversation = ({ path }) => {
  const history = useHistory();
  return ({ id }) => () => history.replace(`/${path}/${encodeId(id)}`);
};

export const useConversationRenderModifier = () => {
  const selectedCompanyId = useSelector(getSelectedCompanyId);
  const conversationsSelector = React.useCallback(
    getConversationsById(selectedCompanyId),
    [selectedCompanyId],
  );
  const conversations = useSelector(conversationsSelector);
  return (render) => (props) => {
    const conversation = conversations[decodeId(props?.id)];
    return conversation ? render({ ...props, conversation }) : null;
  };
};

const useLoopedAction = ({ action, payload, timeout = 0 }) => {
  const dispatch = useDispatch();
  const [loadingId, setLoadingId] = React.useState(payload || null);
  React.useEffect(() => {
    if (!action || !payload) {
      setLoadingId(null);
      return undefined;
    }

    setLoadingId(payload);
    let continueLoop = true;
    const timeoutRef = setTimeout(async () => {
      await dispatch(tryUntilResolve(action(payload), compose(
        (tryAgain) => tryAgain && continueLoop,
        rejectClientErrors,
      )));
      if (continueLoop) {
        setLoadingId((currentPayload) => (
          currentPayload === payload ? null : currentPayload
        ));
      }
    }, timeout);
    return () => {
      continueLoop = false;
      clearTimeout(timeoutRef);
    };
  }, [dispatch, action, payload, timeout]);
  return !!loadingId;
};

export const useLoadContactData = ({ conversation, timeout = 0 }) => {
  let conversationId;
  if (conversation?.type === AGENT) {
    conversationId = splitConversationId(conversation).id;
  } else if (conversation?.type === GROUP) {
    conversationId = conversation.company;
  } else {
    conversationId = conversation?.id;
  }
  const loading = useLoopedAction({
    action: loadContactData,
    payload: conversationId,
    timeout,
  });
  return loading;
};

export const useRefreshMessages = ({ conversation }) => {
  const conversationId = conversation?.id;
  const infoSelector = React.useCallback(
    getMessagesInfo(getSelectedCompanyId, conversationId),
    [conversationId],
  );
  const info = useSelector(infoSelector);
  const shouldRefresh = info.nextPageMarker && !info.upToDate;
  const loading = useLoopedAction({
    action: shouldRefresh ? refreshMessages : null,
    payload: conversationId,
  });
  return shouldRefresh && loading;
};

export const useMarkReadStamp = ({ conversation, markReadStamp = true }) => {
  const dispatch = useDispatch();
  const lastMessageSelector = React.useCallback(
    getLastMessage(getSelectedCompanyId, conversation.id),
    [conversation.id],
  );
  const lastMessageId = useSelector(lastMessageSelector)?.id;
  React.useEffect(() => {
    if (markReadStamp) {
      dispatch(markAgentReadStamp(conversation.id));
    }
    dispatch(updateUnreadCounts({ conversationId: conversation.id }));
  }, [dispatch, markReadStamp, conversation.id, lastMessageId]);
};

export const useLoadMessages = (conversationId) => {
  const [topLoadedMessageId, setTopLoadedMessageId] = React.useState(null);
  React.useEffect(() => {
    setTopLoadedMessageId(null);
  }, [conversationId]);

  const topMessageSelector = React.useCallback(
    getTopMessage(getSelectedCompanyId, conversationId),
    [conversationId],
  );
  const topMessage = useSelector(topMessageSelector);
  const { id: topMessageId } = topMessage || {};
  const loading = topLoadedMessageId === topMessageId;

  const dispatch = useDispatch();
  const loadMessages = React.useCallback(async () => {
    setTopLoadedMessageId(topMessageId);
    await dispatch(loadMessagesPage(conversationId));
  }, [dispatch, conversationId, topMessageId]);

  return { loading, loadMessages };
};

export const useParsedMessages = ({
  width, messages, openInfo, resendMessage, deleteMessage, showDetails,
}) => {
  const parserSelector = React.useCallback(
    flip(parseMessage(showDetails)),
    [showDetails],
  );
  const messageParser = useSelector(parserSelector);

  const font = useSelector(getFont);
  const { messages: parsedMessages } = React.useMemo(() => MessagesList.renderProps({
    width,
    messages: messages.map((message) => {
      const parsedMessage = messageParser(message);
      if (openInfo && (!parsedMessage.avatarType || parsedMessage.avatarType === 'agent')) {
        parsedMessage.onAuthorClick = () => openInfo(message);
      }
      if (resendMessage && deleteMessage && !parsedMessage.sent) {
        parsedMessage.onResendClick = (resend) => (
          resend ? resendMessage(message) : deleteMessage(message)
        );
      }
      return parsedMessage;
    }),
    font,
  }), [width, messages, messageParser, font, openInfo, resendMessage, deleteMessage]);
  return parsedMessages;
};

export const useGetTemplateKey = (conversation) => {
  const conversationAvatarSelector = React.useCallback(
    conversationAvatar(conversation),
    [conversation],
  );
  const { name } = useSelector(conversationAvatarSelector);
  return React.useCallback((key = '') => {
    switch (key.toLocaleLowerCase()) {
      case 'name':
        return name.split(/\s/).shift();
      case 'fullname':
        return name;
      default:
        return null;
    }
  }, [name]);
};

export const useMessageBarProps = ({ id, sendMessage, actions }) => {
  const [showLocation, setShowLocation] = React.useState(false);
  React.useEffect(() => {
    setShowLocation(false);
  }, [id]);

  const messageBarActions = React.useMemo(
    () => actions?.map(({
      key, label, suboptions, submitOnClick,
    }) => {
      const result = [key, label];
      if (Array.isArray(suboptions)) {
        result.push({ suboptions, submitOnClick: false });
      } else if (submitOnClick !== undefined) {
        result.push({ submitOnClick });
      }
      return result;
    }) ?? [],
    [actions],
  );

  const rawTemplates = useSelector(getTemplates);
  const templates = React.useMemo(() => Object.keys(rawTemplates).reduce((list, key) => {
    if (rawTemplates[key]?.text) {
      return [...list, [key, rawTemplates[key].text]];
    }
    return list;
  }, []), [rawTemplates]);

  let type;
  let title;
  let placeholder;
  const texts = useSelector(getTexts);
  if (showLocation) {
    type = 'location';
    title = texts('Pick a location on the map');
  } else {
    placeholder = texts('Your message here...');
  }

  const onSubmit = React.useCallback(({
    content, file, location, button,
  }) => {
    if (/^\/.+/.test(content)) {
      const firstSpace = /\s/.exec(content)?.index ?? content.length;
      const command = content.slice(1, firstSpace);
      const action = actions?.find(compose(equals(command), prop('key')))?.action;
      if (action) {
        const argument = content.slice(firstSpace + 1);
        action(argument || undefined);
        return;
      }
    }

    if (location) {
      setShowLocation(false);
      sendMessage({ text: content, location });
      return;
    }

    if (content || file) {
      sendMessage({ text: content, file });
      return;
    }

    if (button === 'location') {
      setShowLocation(true);
      return;
    }

    if (button === 'cancel' && showLocation) {
      setShowLocation(false);
    }
  }, [sendMessage, actions, showLocation]);

  return {
    type,
    title,
    placeholder,
    messageBarActions,
    templates,
    onSubmit,
  };
};

export const useTagsReach = (tags = []) => {
  const tagsRef = React.useRef([]);
  const [reach, setReach] = React.useState({});

  const newId = tags?.length ? tagsToId(tags) : undefined;
  if (reach.id !== newId) {
    tagsRef.current = tags;
  }

  const dispatch = useDispatch();
  React.useEffect(() => {
    let mounted = true;
    setReach({ id: newId });
    dispatch(loadContacts({
      tags: tagsRef.current,
    })).then(({ total }) => {
      if (!mounted) {
        return;
      }
      setReach((currentReach) => {
        if (currentReach.id === newId) {
          return { ...currentReach, count: total };
        }
        return currentReach;
      });
    });
    return () => {
      mounted = false;
    };
  }, [dispatch, newId]);
  return reach.count ?? null;
};
