import React from 'react';
import PropTypes from 'prop-types';
import {
  isDark, whiteColor, darkenColor, useTheme,
} from '@twnel/web-components';

const Splash = ({ column }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
      }}
    >
      {column?.position === 'right' ? (
        <div
          style={{
            flexGrow: 1,
          }}
        />
      ) : null}
      {column?.width ? (
        <div
          style={{
            width: `${column.width}px`,
            backgroundColor: isDark(theme.background)
              ? darkenColor(4, theme.background)
              : whiteColor(9, theme.sidebar.background),
          }}
        />
      ) : null}
    </div>
  );
};

Splash.propTypes = {
  column: PropTypes.shape({
    width: PropTypes.number,
    position: PropTypes.string,
  }),
};

Splash.defaultProps = {
  column: undefined,
};

export default Splash;
