import React from 'react';
import PropTypes from 'prop-types';
import { List } from '@twnel/web-components';
import SearchBar from './SearchBar';

const SearchableList = ({
  height, loading, items, itemHeight, renderItem, searchPlaceholder, emptyLabel,
  nextPage, onQuery, hideSpinner,
}) => (
  <div>
    <SearchBar
      placeholder={searchPlaceholder}
      onQuery={onQuery}
    />
    {loading || items.length ? (
      <List
        containerHeight={height - SearchBar.HEIGHT}
        heights={typeof itemHeight === 'function'
          ? items.map(itemHeight) : itemHeight}
        loading={loading && (!hideSpinner || items.length === 0)}
        loadNextPage={nextPage}
      >
        {items.map(renderItem)}
      </List>
    ) : (
      <div
        style={{
          height: height - SearchBar.HEIGHT,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontStyle: 'italic',
          opacity: 0.80,
        }}
      >
        <span
          style={{
            marginTop: `-${SearchBar.HEIGHT}px`,
            maxWidth: '260px',
            textAlign: 'center',
          }}
        >
          {emptyLabel}
        </span>
      </div>
    )}
  </div>
);

SearchableList.propTypes = {
  height: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  itemHeight: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.number,
  ]).isRequired,
  renderItem: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
  emptyLabel: PropTypes.string,
  nextPage: PropTypes.func,
  onQuery: PropTypes.func.isRequired,
  hideSpinner: PropTypes.bool,
};

SearchableList.defaultProps = {
  loading: false,
  searchPlaceholder: '',
  emptyLabel: '',
  nextPage: undefined,
  hideSpinner: false,
};

export default SearchableList;
