import { pluck, add } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import {
  Item, List, IconButton, contrastColor, useTheme, parseEmoji,
} from '@twnel/web-components';

const LABEL_HEIGHT = 50;
const FIRST_LABEL = 60 + 5;
const EMPTY = {
  HEIGHT: 40,
  LINE: 24,
};

const Label = styled.div`
  position: relative;
  height: ${LABEL_HEIGHT - 10}px;
  margin: 5px 0;
  box-sizing: border-box;
  border: thin solid ${({ theme }) => contrastColor(1, theme.background)};
  border-width: thin 0;
  > *:first-child {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 0.9rem;
    opacity: 0.8;
    padding: 0 0.5rem;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.background};
    border-width: 0 0 thin;
    @media (hover: hover) {
      i:first-child {
        margin-right: 0.4rem;
        transition: transform 0.2s ease-out;
      }
      &:hover i:first-child {
        transform: scale(1.2);
      }
    }
  }
  ${IconButton} {
    position: absolute;
    bottom: calc(50% - ${IconButton.small.height} /  2);
    right: 0.5rem;
    background-color: transparent;
    @media (hover: hover) {
      &:hover {
        background-color: ${({ theme }) => theme.background};
      }
    }
  }
`;

const Container = styled.div`
  ${Label} {
    border-width: thin ${({ scrollVisible }) => (scrollVisible ? 'thin' : 0)} thin 0;
  }
`;

const FirstLabel = styled(Label)`
  height: ${FIRST_LABEL - 5}px;
  margin: 0 0 5px;
  border-width: 0 0 thin;
  > *:first-child {
    padding-top: ${FIRST_LABEL - LABEL_HEIGHT + 5}px;
  }
  ${IconButton} {
    top: calc(50% + ${(FIRST_LABEL - LABEL_HEIGHT + 5) / 2}px - ${IconButton.small.height} /  2);
  }
`;

const EmptyCell = styled.div`
  display: flex;
  align-items: center;
  line-height: ${EMPTY.LINE}px;
  min-height: ${EMPTY.HEIGHT}px;
  font-size: 0.9rem;
  font-style: italic;
  opacity: 0.80;
  padding: 0 ${EMPTY.LINE / 3}px;
`;

const EmptyItem = ({ height, content }) => {
  const contentRef = React.useRef(null);
  React.useEffect(() => {
    if (contentRef.current) {
      parseEmoji({ node: contentRef.current });
    }
  });
  return (
    <EmptyCell
      ref={contentRef}
      style={{ height: `${height}px` }}
    >
      {content}
    </EmptyCell>
  );
};

EmptyItem.propTypes = {
  height: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
};

EmptyItem.getHeight = ({ width, font, content }) => Math.max(
  EMPTY.HEIGHT,
  (font.linesInText(width - (EMPTY.LINE * (2 / 3)), content).length + (2 / 3)) * EMPTY.LINE,
);

const useToggleItems = () => {
  const [collapseItems, setCollapseItems] = React.useState({});
  return [
    collapseItems,
    (key) => {
      setCollapseItems({ ...collapseItems, [key]: !collapseItems[key] });
    },
  ];
};

const LabeledList = ({
  width, height, sections, loading,
}) => {
  const theme = useTheme();
  const [collapseItems, toggleCollapseItems] = useToggleItems();
  const [elements, heights] = sections.reduce((result, section) => {
    const {
      key, label, items, collapsable, action,
    } = section;
    if (!items?.length) {
      return result;
    }
    const itemList = (!collapseItems[key] && items) || [];
    const LabelComponent = result[0].length === 0 ? FirstLabel : Label;
    const labelAction = action ? (
      <IconButton
        size={IconButton.small}
        onClick={action}
      >
        <i className="fas fa-plus" />
      </IconButton>
    ) : null;
    const labelElement = (
      <React.Fragment key={key}>
        <ThemeProvider
          theme={{
            ...theme,
            background: contrastColor(1, theme.background),
          }}
        >
          {collapsable ? (
            <LabelComponent>
              <Item onClick={() => toggleCollapseItems(key)}>
                <i className={`fas ${collapseItems[key] ? 'fa-caret-down' : 'fa-caret-up'}`} />
                <span>{label}</span>
              </Item>
              {labelAction}
            </LabelComponent>
          ) : (
            <LabelComponent>
              <span>{label}</span>
              {labelAction}
            </LabelComponent>
          )}
        </ThemeProvider>
      </React.Fragment>
    );
    return [[
      ...result[0],
      labelElement,
      ...pluck(0, itemList),
    ], [
      ...result[1],
      result[1].length === 0 ? FIRST_LABEL : LABEL_HEIGHT,
      ...pluck(1, itemList),
    ]];
  }, [[], []]);
  return (
    <Container
      scrollVisible={heights.reduce(add, 0) > height}
    >
      <List
        containerHeight={height}
        style={{
          width: `${width}px`,
          boxSizing: 'border-box',
          borderRight: `thin solid ${contrastColor(4, theme.background)}`,
        }}
        heights={heights}
        loading={loading}
      >
        {elements}
      </List>
    </Container>
  );
};

LabeledList.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    items: PropTypes.arrayOf((propValue, index, componentName) => {
      PropTypes.checkPropTypes({
        0: PropTypes.element,
        1: PropTypes.number,
      }, propValue[index], 'items', componentName);
    }),
    collapsable: PropTypes.bool,
    action: PropTypes.func,
  })),
  loading: PropTypes.bool,
};

LabeledList.defaultProps = {
  sections: [],
  loading: false,
};

LabeledList.emptyItem = ({
  key, font, width = 0, content = '',
}) => {
  const height = EmptyItem.getHeight({ font, width: width - 1, content });
  return [(
    <EmptyItem
      key={key}
      height={height}
      content={content}
    />
  ), height];
};

export default LabeledList;
