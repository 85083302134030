import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Item, Badge } from '@twnel/web-components';

const SectionContainer = styled.div`
  flex-shrink: 0;
  height: 3.75rem;
  padding: 0 0.9375rem;
`;

const SectionLabel = styled.div`
  font-weight: 600;
  font-size: 0.9rem;
  opacity: 0.8;
`;

const CollapsableContainer = styled(SectionContainer)`
  display: flex;
  align-items: center;
  @media (hover: hover) {
    i {
      transition: transform 0.2s ease-out;
    }
    &:hover i, &:active i {
      transform: scale(1.2);
    }
  }
`;

const CollapsableSection = ({
  label, badge, children, className,
}) => {
  const [showFilters, setShowFilters] = React.useState(false);
  const toggleFilters = () => setShowFilters(!showFilters);
  return (
    <div className={className}>
      <Item>
        <CollapsableContainer onClick={toggleFilters}>
          <SectionLabel style={{ flexGrow: 1 }}>
            {label}
          </SectionLabel>
          {!showFilters && badge ? (
            <Badge>
              {badge}
            </Badge>
          ) : (
            <i className={`fas ${showFilters ? 'fa-caret-up' : 'fa-caret-down'}`} />
          )}
        </CollapsableContainer>
      </Item>
      {showFilters ? children : null}
    </div>
  );
};

CollapsableSection.propTypes = {
  label: PropTypes.string,
  badge: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
};

CollapsableSection.defaultProps = {
  label: '',
  badge: undefined,
  children: null,
  className: undefined,
};

export {
  SectionContainer,
  SectionLabel,
  CollapsableSection,
};
