import { mergeDeepRight } from 'ramda';
import { pickExisting } from '@twnel/web-components';
import { GENERAL, UPDATE_GENERAL_INFO, UPDATE_USER_PROFILES } from '../constants';

const user = (state = {
  online: -1,
  autolocking: -1,
  profiles: {},
}, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_GENERAL_INFO: {
      const updates = pickExisting([
        'online',
        'autolocking',
        'conversationsUpToDate',
      ], payload);
      return { ...state, ...updates };
    }
    case UPDATE_USER_PROFILES:
      return {
        ...state,
        profiles: mergeDeepRight(state.profiles, payload),
      };
    default:
      return state;
  }
};

export default {
  [GENERAL]: user,
};
