import { prop, merge } from 'ramda';
import { loadTexts } from '@twnel/web-components';

export default function fetchTexts() {
  return loadTexts(async (locale) => {
    let loaders;
    switch (locale) {
      case 'es':
        loaders = [
          import(
            /* webpackChunkName: "esLocale" */
            '@twnel/web-components/lib/es.json'
          ),
          import(
            /* webpackChunkName: "esLocale" */
            'resources/localization/es.json'
          ),
        ];
        break;
      case 'pt':
        loaders = [
          import(
            /* webpackChunkName: "ptLocale" */
            '@twnel/web-components/lib/pt.json'
          ),
          import(
            /* webpackChunkName: "ptLocale" */
            'resources/localization/pt.json'
          ),
        ];
        break;
      default:
        return {};
    }
    const result = await Promise.all(loaders);
    return result.map(prop('default')).reduce(merge);
  });
}
