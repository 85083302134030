import {
  filter, compose, not, equals, prop, insert, assoc, remove, omit,
} from 'ramda';
import { networkError, throwNetworkError } from '@twnel/web-components';
import {
  parseApiCompany, updateCompanyChatbot, deleteCompanyChatbot,
} from '@twnel/utils-js/lib/web';
import {
  getSelectedCompanyId, getCompany, updateCompany, getBusinessUnit, updateBusinessUnit,
  deleteBusinessUnit, batchRefreshCompany,
} from '@twnel/companies-login';
import { CONVERSATIONS } from '../constants';
import { getUserInfo } from '../selectors';

export const fetchCompany = (companyId) => async (dispatch, getState, getContext) => {
  const { cacheStore } = getContext();
  const companyRequests = cacheStore.get(`${CONVERSATIONS}/companyRequests`);
  let promise = companyRequests.get(companyId);
  if (promise) {
    return promise;
  }

  promise = (async function makeCompanyRequest() {
    const { online: onlineSince } = getUserInfo(getState());
    const updated = await dispatch(batchRefreshCompany(companyId, {
      expiration: onlineSince,
      includeAgents: true,
    }));
    companyRequests.delete(companyId);
    return updated;
  }());

  companyRequests.set(companyId, promise);
  return promise;
};

export const postCompany = (company, props) => async (dispatch, getState, getContext) => {
  const oldCompany = getCompany(company.id, getState());
  dispatch(updateCompany(company));
  const { api } = getContext();
  try {
    await api.companies.update(company, props);
  } catch (error) {
    dispatch(updateCompany(oldCompany));
    throw networkError(error);
  }
};

export const searchCompanies = (query) => async (dispatch, getState, getContext) => {
  const { api } = getContext();
  const selectedCompany = getCompany(getSelectedCompanyId, getState());
  const result = await api.companies.search(query)
    .catch(throwNetworkError);
  return filter(
    compose(not, equals(selectedCompany.id), prop('id')),
    result,
  );
};

export const postBusinessUnit = (businessUnit) => async (dispatch, getState, getContext) => {
  const { api } = getContext();
  const company = getCompany(getSelectedCompanyId, getState());
  const request = businessUnit.id
    ? api.companies.updateBusinessUnit
    : api.companies.createBusinessUnit;

  try {
    await request(businessUnit);
  } catch (error) {
    throw networkError(error);
  }
  dispatch(updateBusinessUnit(company, businessUnit));
};

export const removeBusinessUnit = (businessUnitId) => async (dispatch, getState, getContext) => {
  const { api } = getContext();
  const company = getCompany(getSelectedCompanyId, getState());
  const businessUnit = getBusinessUnit(company.id, businessUnitId, getState());
  await api.companies.deleteBusinessUnit(businessUnitId)
    .catch(throwNetworkError);
  dispatch(deleteBusinessUnit(company, businessUnit));
};

export const postBusinessTopic = (
  businessUnitId,
  topic,
) => async (dispatch, getState, getContext) => {
  const company = getCompany(getSelectedCompanyId, getState());
  const businessUnit = getBusinessUnit(company.id, businessUnitId, getState());
  const topicIndex = businessUnit.topics.find(compose(equals(topic.id), prop('id')));
  const updatedTopics = topicIndex
    ? insert(topicIndex, topic, businessUnit.topics)
    : [...businessUnit.topics, topic]
      .sort(({ name: a }, { name: b }) => a.localeCompare(b));
  const updatedBusinessUnit = assoc('topics', updatedTopics, businessUnit);

  const { api } = getContext();
  const request = topic.id
    ? api.companies.updateBusinessTopic
    : api.companies.createBusinessTopic;

  try {
    await request(updatedBusinessUnit);
  } catch (error) {
    throw networkError(error);
  }
  dispatch(updateBusinessUnit(company, businessUnit));
};

export const removeBusinessTopic = (
  businessUnitId,
  topicId,
) => async (dispatch, getState, getContext) => {
  const company = getCompany(getSelectedCompanyId, getState());
  const businessUnit = getBusinessUnit(company.id, businessUnitId, getState());
  if (!businessUnit) {
    return;
  }

  const topicIndex = businessUnit.topics.find(compose(equals(topicId), prop('id')));
  if (!topicIndex) {
    return;
  }

  const { api } = getContext();
  await api.companies.deleteBusinessTopic(businessUnitId, topicId)
    .catch(throwNetworkError);

  const updatedTopics = remove(topicIndex, 1, businessUnit.topics);
  const updatedBusinessUnit = assoc('topics', updatedTopics, businessUnit);
  dispatch(updateBusinessUnit(company, updatedBusinessUnit));
};

export const onCompanyUpdate = ({ event, payload }) => (dispatch) => {
  switch (event) {
    case 'update': {
      const company = omit(
        ['userAgent', 'apikey', 'token'],
        parseApiCompany(payload),
      );
      dispatch(updateCompany(company));
      break;
    }
    default:
      break;
  }
};

export const onBusinessUnitUpdate = ({ event, payload }) => (dispatch, getState) => {
  switch (event) {
    case 'create':
    case 'update': {
      const company = getCompany(getSelectedCompanyId, getState());
      dispatch(updateBusinessUnit(company, payload));
      break;
    }
    case 'delete': {
      const company = getCompany(getSelectedCompanyId, getState());
      dispatch(deleteBusinessUnit(company, payload));
      break;
    }
    default:
      break;
  }
};

export const onChatbotUpdate = ({ event, payload }) => (dispatch, getState) => {
  switch (event) {
    case 'create':
    case 'update': {
      const company = updateCompanyChatbot(
        getCompany(getSelectedCompanyId, getState()),
        payload,
      );
      dispatch(updateCompany(company));
      break;
    }
    case 'delete': {
      const company = deleteCompanyChatbot(
        getCompany(getSelectedCompanyId, getState()),
        payload.id,
      );
      dispatch(updateCompany(company));
      break;
    }
    default:
      break;
  }
};
