import React from 'react';
import PropTypes from 'prop-types';
import { ImageModal } from '@twnel/web-components';

const FullscreenImage = ({ fullscreenContent, closeFullscreen }) => {
  if (!fullscreenContent) {
    return null;
  }
  const { name, src } = fullscreenContent;
  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        zIndex: 10,
      }}
    >
      <ImageModal name={name} src={src} onClose={closeFullscreen} />
    </div>
  );
};

FullscreenImage.propTypes = {
  fullscreenContent: PropTypes.shape({
    name: PropTypes.string,
    src: PropTypes.string.isRequired,
  }),
  closeFullscreen: PropTypes.func.isRequired,
};

FullscreenImage.defaultProps = {
  fullscreenContent: null,
};

export default FullscreenImage;
