import { assoc } from 'ramda';
import { getSelectedCompanyId } from '@twnel/companies-login';
import { getConversation } from 'src/data/selectors';
import { updateConversations } from '../basic';

export const classifyConversation = (conversationId, topic) => (dispatch, getState) => {
  const companyId = getSelectedCompanyId(getState());
  const conversation = getConversation(companyId, conversationId, getState());
  if (!conversation) {
    throw Error('Conversation not found in memory');
  }

  const data = assoc('topic_selected', {
    name: topic.name,
    business_unit_id: topic.business_unit_id,
    business_topic_id: topic.id,
  }, conversation.data);
  dispatch(updateConversations(companyId, [
    assoc('data', data, conversation),
  ]));
};

export const filterBusinessUnits = (conversation, businessUnits) => {
  const { tags: conversationTags } = conversation;
  return businessUnits.filter(({ tags }) => tags.reduce(
    (result, tag) => result && conversationTags.indexOf(tag) >= 0,
    true,
  ));
};
