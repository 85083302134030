import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SelectInput } from '@twnel/web-components';
import { SectionContainer, SectionLabel } from './misc';

const SectionValue = styled.div`
  display: flex;
  height: 2.5rem;
  align-items: center;
  white-space: nowrap;
  box-sizing: border-box;
`;

const SelectFilter = ({
  label, value, options, onChange,
}) => (
  <SectionContainer
    style={{
      height: 'auto',
      margin: '0.325rem 0 1.3rem 0.325rem',
    }}
  >
    <SectionLabel>
      {label}
    </SectionLabel>
    <SectionValue>
      <div style={{ width: '100%' }}>
        <SelectInput
          flat
          options={options}
          selectedOption={value}
          onSelectOptionHandle={(event) => {
            const newValue = event?.target?.value;
            onChange(newValue);
          }}
        />
      </div>
    </SectionValue>
  </SectionContainer>
);

SelectFilter.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    text: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
};

SelectFilter.defaultProps = {
  label: '',
  value: null,
};

export default SelectFilter;
