import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@twnel/web-components';

const IconLink = ({ url }) => (
  <IconButton
    as="a"
    href={url}
    target="_blank"
    size={{
      width: '1.625rem',
      height: '1.5rem',
      fontSize: '0.80rem',
      scale: 1.05,
    }}
    style={{
      margin: '0 0.15rem',
      textDecoration: 'none',
      borderRadius: '0.75rem',
    }}
  >
    <i className="fas fa-link" />
  </IconButton>
);

IconLink.propTypes = {
  url: PropTypes.string.isRequired,
};

export default IconLink;
