import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getTexts } from '@twnel/web-components';

const Body = ({
  width, height, sections, shouldRefresh, refreshData,
}) => {
  const texts = useSelector(getTexts);
  React.useEffect(() => {
    if (shouldRefresh) {
      refreshData();
    }
  }, [shouldRefresh, refreshData]);
  return (
    <Switch>
      {sections.map(({ path, Component, Fallback }) => (
        <Route key={`route:${path}`} path={path}>
          <Component
            width={width}
            height={height}
            fallback={Fallback ? <Fallback /> : null}
            texts={texts}
          />
        </Route>
      ))}
      <Redirect to={sections[0].path} />
    </Switch>
  );
};

Body.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  sections: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string.isRequired,
    Component: PropTypes.elementType.isRequired,
    Fallback: PropTypes.elementType,
  })).isRequired,
  shouldRefresh: PropTypes.bool.isRequired,
  refreshData: PropTypes.func.isRequired,
};

Body.defaultProps = {
  width: undefined,
  height: undefined,
};

export default Body;
