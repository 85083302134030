import { curryN, always, reduce } from 'ramda';
import { throwNetworkError } from '@twnel/web-components';
import { uploadS3, signedUrlS3 } from '@twnel/utils-js/lib/aws';
import {
  splitConversationId, isCompanyId, isGroup, CONVERSATION_TYPE,
} from '@twnel/utils-js/lib/web';
import {
  getAWS, getSelectedCompanyId, getUserAgent, getCompany,
} from '@twnel/companies-login';
import { CONVERSATIONS, UPDATE_USER_PROFILES } from 'src/data/constants';
import { getUserProfilesById, getConversation, getContact } from 'src/data/selectors';
import { updateContacts } from '../basic';
import { fetchCompany } from '../companies';

const {
  AGENT, BUSINESS, CUSTOMER,
} = CONVERSATION_TYPE;

const MAX_CONTACT_QUERY = 50;
const PROFILE_EXPIRATION = 1000 * 60 * 20; // 20 minutes

const splitArray = (max, array) => {
  let count = 0;
  const result = [];
  while (count < array.length) {
    result.push(array.slice(count, count + max));
    count += max;
  }
  return result;
};

const fetchSingleContact = curryN(3, async (state, { api, cacheStore }, { id, singleBusiness }) => {
  const company = getCompany(getSelectedCompanyId, state);
  const contact = getConversation(company.id, id, state);
  if (contact) {
    return { total: 1, conversations: [contact] };
  }

  const singleContactRequests = cacheStore.get(`${CONVERSATIONS}/contactRequests`);
  let promise = singleContactRequests.get(id);
  if (promise) {
    return promise;
  }

  let params = { query: id, company, perPage: 1 };
  if (singleBusiness) {
    params = { ...params, singleBusiness, country: 'b2b' };
  }

  promise = (async function fetchContacts() {
    try {
      const result = await api.contacts.get(params);
      singleContactRequests.delete(id);
      return result;
    } catch (error) {
      singleContactRequests.delete(id);
      throw error;
    }
  }());
  singleContactRequests.set(id, promise);
  return promise;
});

const queryContacts = async (state, { api }, {
  query = '',
  list = [],
  tags = [],
  twnelUser,
  onlyCompanies,
  page = 1,
  perPage = 1,
  download = false,
}) => {
  const company = getCompany(getSelectedCompanyId, state);
  const userAgent = getUserAgent(state);
  if (!userAgent.tags) {
    throw new Error('User agent is missing the \'tags\' property');
  }
  const params = {
    query,
    list,
    company,
    tags: [...userAgent.tags, ...tags],
  };
  if (onlyCompanies) {
    params.country = 'b2b';
  } else {
    params.twnelUser = twnelUser;
  }
  if (download) {
    const aws = getAWS(state);
    const fileKey = await api.conversations.download(params);
    return signedUrlS3({
      aws,
      key: fileKey,
      bucket: 'twnelcontacts',
    });
  }
  return api.contacts.get({
    ...params,
    page,
    perPage,
  });
};

export const loadContacts = (query = {}) => (dispatch, getState, getContext) => {
  if (query.list?.length) {
    const [numbers, companies] = query.list.reduce((result, contact) => (
      isCompanyId(contact)
        ? [result[0], [...result[1], contact]]
        : [[...result[0], contact], result[1]]
    ), [[], []]);
    const numbersChunks = splitArray(MAX_CONTACT_QUERY, numbers);
    const fetchContact = fetchSingleContact(getState(), getContext());
    return Promise.all([
      ...numbersChunks.map((list) => queryContacts(
        getState(),
        getContext(),
        { ...query, list },
      )),
      ...companies.map((company) => fetchContact({
        id: company,
        singleBusiness: true,
      })),
    ]).then(reduce((result, { total = 0, conversations = [] }) => ({
      total: result.total + total,
      conversations: [...result.conversations, ...conversations],
    }), { total: 0, conversations: [] }));
  }
  const request = query.id ? fetchSingleContact : queryContacts;
  return request(getState(), getContext(), query)
    .catch(throwNetworkError);
};

export const uploadContacts = ({ contact, file }) => (dispatch, getState, getContext) => {
  let promise;
  const { api } = getContext();
  if (contact) {
    promise = api.conversations.update([contact])
      .then(always({ ok: true }));
  } else if (file) {
    const aws = getAWS(getState());
    const company = getCompany(getSelectedCompanyId, getState());
    promise = uploadS3({ aws, file })
      .then(({ url }) => api.conversations.upload({ company, fileUrl: url }));
  }
  return promise.catch(throwNetworkError);
};

export const downloadContacts = (query) => (dispatch, getState, getContext) => {
  const queryParams = { ...query, download: true };
  return queryContacts(getState(), getContext(), queryParams)
    .catch(throwNetworkError);
};

const fetchRelatedCompanies = (conversations) => async (dispatch) => {
  const request = async (conversationId, companyId) => {
    const updated = await dispatch(fetchCompany(companyId));
    return updated ? conversationId : [];
  };
  return Promise.all(conversations.reduce((promises, conversation) => {
    if (isGroup(conversation)) {
      return [...promises, request(conversation.id, conversation.company)];
    }
    if (conversation.type === BUSINESS || conversation.type === AGENT) {
      const { id } = splitConversationId(conversation);
      return [...promises, request(conversation.id, id)];
    }
    return promises;
  }, []));
};

export const fetchConversationsOrigin = (
  conversations,
  { ignoreCache = true } = {},
) => async (dispatch, getState) => {
  const companyId = getSelectedCompanyId(getState());
  const [external, list] = conversations.reduce(([externalIds, result], conversation) => {
    if (!conversation) {
      return [externalIds, result];
    }

    let conversationCompanyId;
    if (isGroup(conversation)) {
      conversationCompanyId = conversation.company;
    } else if (conversation.type === BUSINESS) {
      conversationCompanyId = conversation.id;
    } else if (conversation.type === AGENT) {
      const { id } = splitConversationId(conversation);
      conversationCompanyId = id;
    }

    if (!conversationCompanyId || conversationCompanyId === companyId) {
      return [externalIds, [...result, conversation]];
    }

    const companyContact = getContact(companyId, conversationCompanyId, getState());
    if (companyContact?.tags && !ignoreCache) {
      return [externalIds, [...result, {
        ...conversation,
        externalTags: conversation.tags,
        tags: companyContact.tags,
      }]];
    }

    return [{
      ...(externalIds || {}),
      [conversation.id]: conversationCompanyId,
    }, [...result, conversation]];
  }, [null, []]);

  if (!external) {
    return list;
  }

  const companyContacts = await (async function loadPage(page = 1, loaded = []) {
    const { total, conversations: batch } = await dispatch(loadContacts({
      page,
      perPage: MAX_CONTACT_QUERY,
      twnelUser: true,
      onlyCompanies: true,
    }));
    const newList = [...loaded, ...batch];
    return newList.length < total ? loadPage(page + 1, newList) : newList;
  }());
  dispatch(updateContacts(companyId, companyContacts));

  const tagsById = companyContacts.reduce((result, { id, tags }) => (id && tags ? {
    ...result,
    [id]: tags,
  } : result), {});
  return list.reduce((result, conversation) => {
    const conversationCompanyId = external[conversation.id];
    if (!conversationCompanyId) {
      return [...result, conversation];
    }

    const tags = tagsById[conversationCompanyId];
    if (!tags) {
      return result;
    }

    return [...result, {
      ...conversation,
      externalTags: conversation.tags,
      tags,
    }];
  }, []);
};

const fetchUserProfiles = (userIds = []) => async (dispatch, getState, getContext) => {
  const expiredProfiles = userIds.filter((id) => {
    const profiles = getUserProfilesById(getState());
    return !profiles?.[id]?.expires || profiles[id].expires < Date.now();
  });
  if (expiredProfiles.length === 0) {
    return;
  }

  let usersInfo;
  const { api } = getContext();
  try {
    usersInfo = await api.users.get(expiredProfiles);
  } catch (error) {
    usersInfo = [];
  }

  const updates = usersInfo.reduce((result, { phoneNumber, name, photo }) => ({
    ...result,
    [phoneNumber]: { name, photo },
  }), {});
  const updatedProfiles = expiredProfiles.reduce((result, id) => {
    const update = updates[id];
    return {
      ...result,
      [id]: update ? {
        name: update.name,
        photo: update.photo,
        expires: Number.MAX_SAFE_INTEGER,
      } : {
        expires: Date.now() + PROFILE_EXPIRATION,
      },
    };
  }, {});
  dispatch({
    type: UPDATE_USER_PROFILES,
    payload: updatedProfiles,
  });
};

// (string || [string]) => action
export const loadContactData = (param) => async (dispatch, getState, getContext) => {
  if (!param) {
    return;
  }

  const { cacheStore } = getContext();
  const contactRequests = cacheStore.get(`${CONVERSATIONS}/loadContactData`);
  const { contacts: queuedContacts, timeout } = contactRequests.get('queue') || {};

  const selectedCompanyId = getSelectedCompanyId(getState());
  const [contacts, additions] = (Array.isArray(param) ? param : [param]).reduce(
    ([list, count], contact) => {
      if (
        !contact
        || typeof contact !== 'string'
        || contact === selectedCompanyId
        || list[contact]
      ) {
        return [list, count];
      }
      return [{ ...list, [contact]: true }, count + 1];
    },
    [queuedContacts || {}, 0],
  );

  if (!additions) {
    return;
  }

  clearTimeout(timeout);
  contactRequests.set('queue', {
    contacts,
    timeout: setTimeout(async () => {
      const result = Object.keys(contacts).reduce(([existing, missing], id) => {
        const contact = getContact(selectedCompanyId, id, getState());
        if (contact) {
          return [[...existing, contact], missing];
        }
        return [existing, [...missing, id]];
      }, [[], []]);

      if (result[1].length) {
        const { conversations: missingContacts } = await dispatch(loadContacts({
          list: result[1],
        }));
        dispatch(updateContacts(selectedCompanyId, missingContacts));
        result[0] = [...result[0], ...missingContacts];
      }

      const customerIds = result[0].reduce((list, conversation) => {
        const { id, type } = conversation;
        return type === CUSTOMER ? [...list, id] : list;
      }, []);
      dispatch(fetchRelatedCompanies(result[0]));
      dispatch(fetchUserProfiles(customerIds));
      contactRequests.delete('queue');
    }, 500),
  });
};
