export const GENERAL = 'general';
export const CONVERSATIONS = 'conversations';
export const MESSAGES = 'messages';

export const UPDATE_CONVERSATIONS = `${CONVERSATIONS}/update`;
export const UPDATE_CONTACTS = `${CONVERSATIONS}/contacts`;
export const UPDATE_GENERAL_INFO = `${GENERAL}/update`;
export const UPDATE_USER_PROFILES = `${GENERAL}/profiles`;
export const UPDATE_MESSAGES = `${MESSAGES}/update`;
export const UPDATE_MESSAGES_INFO = `${MESSAGES}/info`;
export const DELETE_MESSAGES = `${MESSAGES}/delete`;

export const USER_CONFIG = 'chat';
export const USER_STATUS = {
  AVAILABLE: 'CHAT',
  AWAY: 'DND',
};
