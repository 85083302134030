import {
  compose, pick, prop, dissoc, reject, isEmpty, map, fromPairs, toPairs,
} from 'ramda';
import { createSelector } from 'reselect';
import { smartSelector } from '@twnel/web-components';
import { getAgentConfig, companyAutolockerConfiguration } from '@twnel/utils-js/lib/web';
import { getUserAgent, getSelectedCompanyId, getCompany } from '@twnel/companies-login';
import { GENERAL, USER_CONFIG, USER_STATUS } from '../constants';

const { AVAILABLE, AWAY } = USER_STATUS;

const getGeneralData = (state) => state[GENERAL];

// state -> {online, conversationsUpToDate}
export const getUserInfo = createSelector(
  getGeneralData,
  pick([
    'online',
    'autolocking',
    'conversationsUpToDate',
  ]),
);

const getAutolockerConfiguration = createSelector(
  getCompany(getSelectedCompanyId),
  (company) => {
    const { enabled, activeByDefault } = companyAutolockerConfiguration(company);
    return enabled ? {
      enabled: true,
      defaultStatus: activeByDefault ? AVAILABLE : AWAY,
    } : { enabled: false };
  },
);

// state -> bool
export const getIsAutolockerEnabled = createSelector(
  getAutolockerConfiguration,
  prop('enabled'),
);

// companyId -> state -> bool
export const getConversationsAreLoading = (companyId) => smartSelector(
  companyId,
  getUserInfo,
  (company, { conversationsUpToDate }) => conversationsUpToDate !== company,
);

// state -> userConfing
export const getUserConfig = createSelector(
  getUserAgent,
  getAutolockerConfiguration,
  (userAgent, { enabled, defaultStatus }) => {
    const config = getAgentConfig(userAgent, USER_CONFIG) ?? {};
    return enabled ? {
      ...config,
      status: config.status ?? defaultStatus,
    } : dissoc('status', config);
  },
);

// state -> {id: {name, photo}}
export const getUserProfilesById = createSelector(
  getGeneralData,
  prop('profiles'),
);

// state -> {id: {name, photo}}
export const getCleanUserProfiles = createSelector(
  getUserProfilesById,
  compose(
    reject(isEmpty),
    map(pick(['name', 'photo'])),
  ),
);

// (state -> {id: model}) -> state -> {id: model + profile}
export const withUserProfiles = (modelsByIdSelector) => createSelector(
  getCleanUserProfiles,
  modelsByIdSelector,
  (profiles, models) => compose(
    fromPairs,
    map(([id, model]) => [id, profiles[id] ? {
      ...model,
      ...profiles[id],
    } : model]),
    toPairs,
  )(models),
);
