import { identity, compose } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TagsInput } from '@twnel/web-components';
import { cleanTags } from '@twnel/utils-js/lib/web';
import { getSelectedCompany } from 'src/ui/util/connect/selectors';
import { SectionContainer, SectionLabel } from './misc';

const TagsFilter = ({ value, setFilter, texts }) => {
  const { tags } = useSelector(getSelectedCompany);
  const onChange = (newTags) => {
    setFilter({ tags: newTags });
  };
  return (
    <SectionContainer
      style={{
        height: 'auto',
        margin: '0.325rem 0 1.3rem 0.325rem',
      }}
    >
      <SectionLabel>
        {texts('Tags')}
      </SectionLabel>
      <TagsInput
        flat
        value={value}
        tags={tags}
        onChange={compose(onChange, cleanTags)}
        texts={texts}
      />
    </SectionContainer>
  );
};

TagsFilter.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  setFilter: PropTypes.func.isRequired,
  texts: PropTypes.func,
};

TagsFilter.defaultProps = {
  value: [],
  texts: identity,
};

export default TagsFilter;
