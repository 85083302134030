import {
  mergeDeepRight, assoc, compose, without, ifElse, dissoc, omit,
} from 'ramda';
import {
  parseApiConversation, cleanConversationObject, isGroup,
  CONVERSATION_EXPOSURE, CONVERSATION_TYPE,
} from '@twnel/utils-js/lib/web';
import { CONVERSATIONS, UPDATE_CONVERSATIONS, UPDATE_CONTACTS } from '../constants';

const { CUSTOMER } = CONVERSATION_TYPE;
const { DELETED } = CONVERSATION_EXPOSURE;

const cleanModel = ifElse(
  isGroup,
  dissoc('lastMessage'),
  omit(['name', 'photo', 'lastMessage']),
);

const processContacts = (state = {}, contacts = []) => ({
  ...state,
  ...contacts.reduce(({ byId = {} }, newContact) => {
    const oldContact = byId[newContact.id] || {};
    const contact = compose(
      compose(parseApiConversation, cleanConversationObject),
      mergeDeepRight(oldContact),
      cleanModel,
    )(newContact);
    return {
      byId: {
        ...byId,
        [contact.id]: contact,
      },
    };
  }, state),
});

const processConversations = (state = {}, conversations = []) => ({
  ...state,
  ...conversations.reduce(({ byId = {}, conversationIds = [], stamp = 1 }, newConversation) => {
    const oldConversation = byId[newConversation.id];
    if (newConversation.exposure === DELETED && newConversation.type === CUSTOMER) {
      const conversation = compose(
        compose(parseApiConversation, cleanConversationObject),
        mergeDeepRight(oldConversation || {}),
        cleanModel,
      )(newConversation);
      return {
        byId: {
          ...byId,
          [conversation.id]: conversation,
        },
        conversationIds: without([conversation.id], conversationIds),
        stamp,
      };
    }

    if (newConversation.exposure === DELETED) {
      return {
        byId: omit([newConversation.id], byId),
        conversationIds: without([newConversation.id], conversationIds),
        stamp,
      };
    }

    const conversation = compose(
      mergeDeepRight(oldConversation || {}),
      cleanModel,
    )(newConversation);
    if (oldConversation?.sortStamp) {
      conversation.sortStamp = oldConversation.sortStamp;
      return {
        byId: {
          ...byId,
          [conversation.id]: conversation,
        },
        conversationIds,
        stamp,
      };
    }

    conversation.sortStamp = stamp;
    return {
      byId: {
        ...byId,
        [conversation.id]: conversation,
      },
      conversationIds: [...conversationIds, conversation.id],
      stamp: stamp + 1,
    };
  }, state),
});

const conversationsList = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_CONTACTS:
      return processContacts(state, payload.contacts);
    case UPDATE_CONVERSATIONS:
      return processConversations(state, payload.conversations);
    default:
      return state;
  }
};

const conversations = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_CONTACTS:
    case UPDATE_CONVERSATIONS:
      return assoc(
        payload.companyId,
        conversationsList(state[payload.companyId], action),
        state,
      );
    default:
      return state;
  }
};

export default {
  [CONVERSATIONS]: conversations,
};
