import {
  UPDATE_CONVERSATIONS, UPDATE_CONTACTS, UPDATE_MESSAGES, DELETE_MESSAGES,
  UPDATE_MESSAGES_INFO, UPDATE_GENERAL_INFO,
} from '../constants';

export const updateConversations = (companyId, conversations) => ({
  type: UPDATE_CONVERSATIONS,
  payload: { companyId, conversations },
});

export const updateContacts = (companyId, contacts) => ({
  type: UPDATE_CONTACTS,
  payload: { companyId, contacts },
});

export const updateMessages = (companyId, conversationId, messages, insert) => ({
  type: UPDATE_MESSAGES,
  payload: {
    companyId, conversationId, messages, insert,
  },
});

export const deleteMessages = (companyId, conversationId, messages) => ({
  type: DELETE_MESSAGES,
  payload: { companyId, conversationId, messages },
});

export const updateMessagesInfo = (companyId, conversationId, {
  complete, upToDate, nextPageMarker,
}) => ({
  type: UPDATE_MESSAGES_INFO,
  payload: {
    companyId, conversationId, complete, upToDate, nextPageMarker,
  },
});

export const updateUserInfo = ({ online, autolocking, conversationsUpToDate }) => ({
  type: UPDATE_GENERAL_INFO,
  payload: { online, autolocking, conversationsUpToDate },
});
