import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Avatar, Item, Bubble, Tag, Badge, getTexts, parseEmoji,
} from '@twnel/web-components';
import { useServiceTimeDate, useLoadContactData, useShortDate } from 'src/ui/util/connect/hooks';
import { parseConversation } from 'src/ui/util/connect/selectors';

const HEIGHT = 60;

const badgeContent = (texts, badge) => {
  if (badge === undefined || badge === 0) {
    return null;
  }

  let content;
  let size;
  if (!Number.isInteger(badge)) {
    content = null;
    size = '0.85rem';
  } else if (badge === Number.MAX_SAFE_INTEGER) {
    content = texts('New');
    size = '1.10rem';
  } else {
    content = badge > 99 ? '99+' : badge.toString();
    size = '1rem';
  }
  return (
    <div
      style={{
        flexGrow: '1',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <Badge size={size}>
        {content}
      </Badge>
    </div>
  );
};

const ConversationCell = ({ conversation, onClick }) => {
  const texts = useSelector(getTexts);
  const conversationSelector = useSelector(parseConversation);
  const {
    id, name, image, avatarType, companyId, companyName, companyLogo, date, highlightDate,
    empty, tags, highlightTags, topic, message, badge,
  } = conversationSelector(conversation);
  useLoadContactData({ conversation, timeout: 250 });

  const bodyRef = React.useRef(null);
  React.useEffect(() => {
    if (bodyRef.current) {
      parseEmoji({ node: bodyRef.current });
    }
  });

  let body;
  if (empty) {
    body = (
      <span
        style={{
          fontStyle: 'italic',
          opacity: 0.80,
        }}
      >
        {empty}
      </span>
    );
  } else if (message) {
    const { author, content, icon } = message;
    body = (
      <>
        {author ? (
          <>
            <span>{`${author}:`}</span>
            &nbsp;
          </>
        ) : null}
        {icon ? (
          <>
            <i className={icon} />
            &nbsp;
          </>
        ) : null}
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {content}
        </span>
      </>
    );
  } else if (topic) {
    body = (
      <>
        <span style={{ opacity: 0.80 }}>
          {`${texts('Classified as')}:`}
        </span>
        &nbsp;
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: 500,
          }}
        >
          {topic}
        </span>
      </>
    );
  } else if (tags) {
    body = (
      <>
        {tags.map((tag) => (
          <Tag
            key={tag}
            highlight={highlightTags}
          >
            {tag}
          </Tag>
        ))}
      </>
    );
  }

  const dateString = useShortDate(date);
  const serviceTimeColor = useServiceTimeDate();
  return (
    <Item
      style={{
        display: 'flex',
        height: `${HEIGHT}px`,
        padding: '0 0.50rem 0 0.25rem',
      }}
      onClick={onClick}
    >
      <Avatar
        id={id}
        size={HEIGHT}
        image={image}
        companyId={companyId}
        companyLogo={companyLogo}
        avatarType={avatarType}
      />
      <div
        ref={bodyRef}
        style={{
          flexGrow: 2,
          flexBasis: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginLeft: '0.25rem',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            display: 'flex',
            height: '1.25rem',
            alignItems: 'center',
            fontSize: '0.90rem',
          }}
        >
          <span
            style={{
              fontWeight: '600',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              marginRight: '6px',
            }}
          >
            {name}
          </span>
          {companyName ? (
            <Bubble
              height={15}
              fontSize="0.80rem"
              padding={[0, 7, 0, 7]}
              margin={[0, 6, 0, 0]}
            >
              {companyName}
            </Bubble>
          ) : null}
          {dateString ? badgeContent(texts, badge) : null}
        </div>
        <div
          style={{
            display: 'flex',
            height: '1.25rem',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              flexGrow: 1,
              fontSize: '0.90rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {body}
          </span>
          {dateString ? (
            <span
              style={{
                fontSize: '0.86rem',
                opacity: 0.80,
                margin: '0 0 0 5px',
                color: highlightDate ? serviceTimeColor(date) : 'inherit',
                fontWeight: 500,
              }}
            >
              {dateString}
            </span>
          ) : null}
        </div>
      </div>
      {dateString ? null : badgeContent(texts, badge)}
    </Item>
  );
};

ConversationCell.propTypes = {
  conversation: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

ConversationCell.HEIGHT = HEIGHT;

export default ConversationCell;
