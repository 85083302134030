import {
  pick, compose, sort, filter, values, prop,
} from 'ramda';
import { createSelector } from 'reselect';
import { smartSelector, uncurrySelector } from '@twnel/web-components';
import { CONVERSATIONS } from '../constants';
import { withUserProfiles } from './general';

const getAllConversations = (state) => state[CONVERSATIONS];

const getConversations = (companyId) => smartSelector(
  companyId,
  getAllConversations,
  (company, conversations) => conversations[company] || {},
);

export const getConversationsExists = uncurrySelector(2, (companyId) => createSelector(
  getConversations(companyId),
  ({ conversationIds }) => !!conversationIds,
));

const getConversationsDictionary = (companyId) => createSelector(
  getConversations(companyId),
  ({ byId = {}, conversationIds = [] }) => pick(conversationIds, byId),
);

// companyId -> state -> dictionary
export const getConversationsById = uncurrySelector(2, (companyId) => withUserProfiles(
  getConversationsDictionary(companyId),
));

// companyId -> (filter: conversation -> bool) -> state -> conversations
export const getConversationsArray = uncurrySelector(
  3,
  (companyId) => (filterFn) => createSelector(
    getConversationsById(companyId),
    compose(
      sort(({ sortStamp: a }, { sortStamp: b }) => a - b),
      values,
      filter(filterFn),
    ),
  ),
);

// companyId -> conversationId -> state -> conversation
export const getConversation = uncurrySelector(
  3,
  (companyId) => (conversationId) => createSelector(
    getConversationsById(companyId),
    prop(conversationId),
  ),
);

const getContactsById = (companyId) => withUserProfiles(createSelector(
  getConversations(companyId),
  prop('byId'),
));

// companyId -> contactId -> state -> contact
export const getContact = uncurrySelector(
  3,
  (companyId) => (contactId) => createSelector(
    getContactsById(companyId),
    prop(contactId),
  ),
);
