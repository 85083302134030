import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import makeShell from './Shell';

const GlobalStyle = createGlobalStyle`
  html, body, #react {
    height: 100%;
    margin: 0;
  }
`;

export default function makeApp(store) {
  const Shell = makeShell(store);

  const App = () => (
    <Provider store={store}>
      <GlobalStyle />
      <BrowserRouter>
        <Shell />
      </BrowserRouter>
    </Provider>
  );

  return App;
}
