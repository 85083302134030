import { thunkify, compose } from 'ramda';
import { batch } from 'react-redux';
import { setAgentConfig } from '@twnel/utils-js/lib/web';
import { logout, getAppInfo, getUserAgent } from '@twnel/companies-login';
import { logger } from 'src/data/util';
import { getUserInfo } from '../selectors';
import { USER_CONFIG } from '../constants';
import { updateUserInfo } from './basic';
import { postAgent } from './agents';
import { outdateConversations } from './conversations';
import { outdateMessages } from './messages';

export const updateUserConfig = (config) => (dispatch, getState) => {
  const updatedUserAgent = setAgentConfig(
    getUserAgent(getState()),
    USER_CONFIG,
    config,
  );
  return dispatch(postAgent(
    updatedUserAgent,
    { propagate: false },
  ));
};

export const onSocketConnection = (connected) => (dispatch, getState) => {
  const { online } = getUserInfo(getState());
  if (online > 0 && !connected) {
    dispatch(updateUserInfo({ online: -1 }));
  }
  if (online < 0 && connected) {
    batch(() => {
      dispatch(updateUserInfo({ online: Date.now() }));
      dispatch(outdateConversations());
      dispatch(outdateMessages());
    });
  }
};

export const onTokenExpired = () => (dispatch, getState) => {
  const { debug, environment } = getAppInfo(getState());
  const exit = thunkify(compose(dispatch, logout))(environment);
  if (debug) {
    const delay = 5;
    logger(
      { debug, warning: true },
      `TokenExpired: Will redirect in ${delay}m`,
    );
    setTimeout(exit, delay * 60 * 1000);
  } else {
    exit();
  }
};

export const onSocketOverride = () => (dispatch, getState) => {
  const { debug, environment } = getAppInfo(getState());
  const exit = thunkify(compose(dispatch, logout))(environment);
  if (debug) {
    const delay = 5;
    logger(
      { debug, warning: true },
      `SocketOverride: Will redirect in ${delay}m`,
    );
    setTimeout(exit, delay * 60 * 1000);
  } else {
    exit();
  }
};
