import {
  always, curryN, uncurryN, all, contains, find, assoc, dissoc, prop, compose, equals,
} from 'ramda';
import { breakNumber } from '@twnel/web-components';
import {
  splitConversationId, getConversationName, isGroup,
  CONVERSATION_TYPE, CONVERSATION_EXPOSURE,
} from '@twnel/utils-js/lib/web';
import {
  getSelectedCompanyId, getUserAgent, getCompany, getBusinessUnit,
} from '@twnel/companies-login';

const {
  AGENT, BUSINESS, CUSTOMER,
} = CONVERSATION_TYPE;
const {
  ACTIVE, INACTIVE, DELETED,
} = CONVERSATION_EXPOSURE;

export const ARCHIVE = {
  HISTORY: 36 * 60 * 60 * 1000,
  LIMIT: 1000,
};

export const checkAgentTags = curryN(2, (agent, tags = []) => agent.tags
  && all((tag) => contains(tag, tags), agent.tags));

export const isAnonymous = (conversation) => conversation.type === CUSTOMER
  && !getConversationName(conversation);

// agent -> conversation -> bool
const compareCountry = ({ country }) => {
  if (!country) {
    return always(true);
  }
  const agentCountry = country.toLowerCase();
  return ({ id }) => {
    const { country: countryObj } = breakNumber(id);
    return countryObj ? countryObj.toLowerCase() === agentCountry : true;
  };
};

const cleanConversation = uncurryN(2, (state) => (conversation) => {
  // eslint-disable-next-line camelcase
  const topic = conversation.data?.topic_selected;
  const result = {
    ...dissoc('topic', conversation),
    data: assoc('topic_selected', null, conversation.data ?? {}),
  };
  // eslint-disable-next-line camelcase
  if (topic?.business_unit_id && topic?.business_topic_id) {
    const { business_unit_id: businessUnitId, business_topic_id: id, name } = topic;
    const businessUnit = getBusinessUnit(getSelectedCompanyId, businessUnitId, state);
    if (find(compose(equals(id), prop('id')), businessUnit?.topics ?? [])) {
      result.topic = { id, businessUnitId, name };
    }
  }
  return result;
});

export const archiveConversation = uncurryN(2, (state) => (conversation, options) => {
  const { remove = false } = options || {};
  if (isGroup(conversation)) {
    return assoc('exposure', DELETED, conversation);
  }
  return compose(
    cleanConversation(state),
    assoc('exposure', remove ? DELETED : INACTIVE),
  )(conversation);
});

export const isConversationRelevant = (conversation) => conversation.exposure !== INACTIVE || (
  conversation.closed_at
  && conversation.closed_at > Date.now() - ARCHIVE.HISTORY
);

export const cleanAndFilter = (state) => {
  const userAgent = getUserAgent(state);
  const checkCountry = compareCountry(userAgent);
  const company = getCompany(getSelectedCompanyId, state);
  return (conversations) => conversations
    .filter((conversation) => {
      if (!conversation) {
        return false;
      }

      // Ignore b2b conversation with self
      if (conversation.type === BUSINESS && conversation.id === company.id) {
        return false;
      }

      // Check the country/region filter
      if (
        conversation.type === CUSTOMER
        && !company.worldwide
        && !checkCountry(conversation)
      ) {
        return false;
      }

      // Avoid mirror and private intra-a2a conversations
      if (conversation.type === AGENT) {
        const { id, owner, resource } = splitConversationId(conversation);
        if (id === owner) {
          return resource === userAgent.id;
        }
      }

      // Avoid archived conversations a long time ago
      if (!isConversationRelevant(conversation)) {
        return false;
      }

      // Check if tags match with user's
      if (checkAgentTags(userAgent, conversation.tags)) {
        return true;
      }

      // All agents can see anonymous conversations
      if (isAnonymous(conversation)) {
        return true;
      }

      // User can always see his active conversations
      if (
        conversation.type === CUSTOMER
        && conversation.exposure === ACTIVE
        && conversation.agent && conversation.agent.id === userAgent.id
      ) {
        return true;
      }

      return false;
    })
    .map(cleanConversation(state));
};
