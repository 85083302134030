import {
  compose, sort, reverse, head, last, merge, filter, prop, keys, curry, assoc, reduce, map,
  isEmpty, mergeDeepRight, values,
} from 'ramda';
import { batch } from 'react-redux';
import {
  ImageLoader, parseLocation, fileFromDataURL, throwNetworkError,
} from '@twnel/web-components';
import { uploadS3 } from '@twnel/utils-js/lib/aws';
import {
  splitId, splitConversationId, isMessage, newMessage, newSessionMessage, messagesCompare,
  parseApiMessage, sortMessages, getMessageContent, Endpoints,
  CONVERSATION_TYPE, CONTENT_TYPE, DELIVERY_STATE,
} from '@twnel/utils-js/lib/web';
import {
  getAppInfo, getAWS, getSelectedCompanyId, getUserAgentId, getCompany,
} from '@twnel/companies-login';
import {
  getConversation, getMessage, getLastMessage, getMessagesInfo, getAllMessagesInfo,
} from '../selectors';
import { tagsToId, shouldNotifyMessage, messageNotification } from '../util';
import { updateMessages, updateMessagesInfo } from './basic';
import { lockConversation, updateUnreadCounts } from './conversations';

const { AGENT, GROUP } = CONVERSATION_TYPE;
const { LOCATION, IGNORE } = CONTENT_TYPE;
const { NOT_SENT, QUEUED, SENT } = DELIVERY_STATE;

const firstPageMarker = ({ type }) => (type === GROUP ? Date.now() : 1);

const loadPage = ({ conversation, pageMarker }) => async (dispatch, getState, getContext) => {
  const { api } = getContext();
  const company = getCompany(getSelectedCompanyId, getState());
  const result = await api.messages.get({ company, conversation, pageMarker })
    .catch(throwNetworkError);

  const { messages, complete } = result;
  const sortedMessages = compose(sort(messagesCompare), reverse)(messages);

  let nextPageMarker;
  if (conversation.type === GROUP) {
    const oldestMessage = head(sortedMessages);
    nextPageMarker = oldestMessage ? oldestMessage.created_at : pageMarker;
  } else {
    nextPageMarker = pageMarker + 1;
  }

  return {
    messages: sortedMessages,
    complete,
    nextPageMarker,
  };
};

export const loadMessagesPage = (conversationId) => async (dispatch, getState) => {
  const companyId = getSelectedCompanyId(getState());
  const conversation = getConversation(companyId, conversationId, getState());
  if (!conversation) {
    throw new Error(`Conversation not in memory: ${conversationId}`);
  }

  const info = getMessagesInfo(companyId, conversationId, getState());
  if (info.complete) {
    return;
  }

  const pageMarker = info.nextPageMarker || firstPageMarker(conversation);
  const result = await dispatch(loadPage({ conversation, pageMarker }));
  const { messages, complete, nextPageMarker } = result;

  batch(() => {
    dispatch(updateMessagesInfo(
      companyId,
      conversationId,
      {
        complete,
        nextPageMarker,
        upToDate: typeof info.upToDate !== 'undefined' ? info.upToDate : true,
      },
    ));
    dispatch(updateMessages(
      companyId,
      conversationId,
      messages,
      info ? 'left' : 'replace',
    ));
  });
};

export const refreshMessages = (conversationId) => async (dispatch, getState) => {
  const companyId = getSelectedCompanyId(getState());
  const conversation = getConversation(companyId, conversationId, getState());
  if (!conversation) {
    throw new Error(`Conversation not in memory: ${conversationId}`);
  }

  const info = getMessagesInfo(companyId, conversationId, getState());
  if (!info.nextPageMarker || info.upToDate) {
    return;
  }

  const load = async (pageMarker, list = []) => {
    const { messages, nextPageMarker } = await dispatch(loadPage({ conversation, pageMarker }));
    const allNew = list.length > 0 && messages.length > 0
      && head(messages).created_at > last(list).created_at;
    const messagesList = [...messages, ...list];
    return allNew ? load(nextPageMarker, messagesList) : messagesList;
  };

  const lastMessage = getLastMessage(getSelectedCompanyId, conversation.id, getState());
  const firstPage = firstPageMarker(
    conversation,
    lastMessage ? [lastMessage] : [],
  );

  const messages = await load(firstPage);
  batch(() => {
    dispatch(updateMessagesInfo(
      companyId,
      conversationId,
      merge(info, { upToDate: true }),
    ));
    dispatch(updateMessages(
      companyId,
      conversationId,
      messages,
      'right',
    ));
  });
};

export const outdateMessages = () => (dispatch, getState) => {
  const companyId = getSelectedCompanyId(getState());
  const conversationIds = keys(filter(
    prop('upToDate'),
    getAllMessagesInfo(companyId, getState()),
  ));
  batch(() => {
    conversationIds.forEach((conversationId) => {
      dispatch(updateMessagesInfo(
        companyId,
        conversationId,
        { upToDate: false },
      ));
    });
  });
};

export const loadMessageHistory = (conversationId) => (dispatch, getState) => {
  const companyId = getSelectedCompanyId(getState());
  const conversation = getConversation(companyId, conversationId, getState());
  return (async function loop() {
    const info = getMessagesInfo(companyId, conversationId, getState());
    if (
      info.complete
      || (conversation.type === GROUP && Date.now() - info.nextPageMarker > 1.21e+9) // 2 Weeks
      || info.nextPageMarker > 6 // 300 messages
    ) {
      return true;
    }
    await dispatch(loadMessagesPage(conversationId));
    return loop();
  }());
};

const conversationIdFromMessage = ({ groupId, from, to }, state) => {
  if (groupId) {
    return groupId;
  }

  const companyId = getSelectedCompanyId(state);
  const { id: idFrom, agent: agentFrom } = splitId(from);
  const { id: idTo, agent: agentTo } = splitId(to);
  if (idFrom !== companyId && idTo !== companyId) {
    return undefined;
  }

  if (idFrom === companyId && idTo !== companyId) {
    return idTo + (agentTo && agentFrom ? `/${agentTo}/${agentFrom}` : '');
  }
  if (idTo === companyId && idFrom !== companyId) {
    return idFrom + (agentFrom && agentTo ? `/${agentFrom}/${agentTo}` : '');
  }

  const userAgentId = getUserAgentId(state);
  if (agentTo === userAgentId) {
    return `${companyId}/${agentFrom}/${userAgentId}`;
  }
  if (agentFrom === userAgentId) {
    return `${companyId}/${agentTo}/${userAgentId}`;
  }
  return undefined;
};

export const onSocketMessages = (rawMessages = []) => (dispatch, getState, getContext) => {
  const companyId = getSelectedCompanyId(getState());
  const messages = compose(
    reduce((result, message) => {
      if (!message || message.type === IGNORE) {
        return result;
      }

      const conversationId = conversationIdFromMessage(message, getState());
      const conversation = getConversation(companyId, conversationId, getState());
      if (!conversation) {
        return result;
      }

      let messageCopy = message;
      const existingMessage = result[conversationId]?.[message.id]
        ?? getMessage(companyId, conversationId, message.id, getState());
      if (!existingMessage?.notified && shouldNotifyMessage(conversation, message, getState())) {
        const { notificationsManager } = getContext();
        const notification = messageNotification(conversation, message, getState());
        notificationsManager.sendNotification(notification);
        messageCopy = {
          ...messageCopy,
          notified: true,
        };
      }

      return mergeDeepRight(result, {
        [conversationId]: {
          [message.id]: messageCopy,
        },
      });
    }, {}),
    sortMessages,
    map(parseApiMessage),
  )(rawMessages);

  if (isEmpty(messages)) {
    return;
  }

  batch(() => {
    Object.keys(messages).forEach((conversationId) => {
      const list = values(messages[conversationId]);
      dispatch(updateMessages(companyId, conversationId, list));
      dispatch(updateUnreadCounts({
        conversationId,
        messages: list,
      }));
    });
  });
};

const uploadMedia = (message) => async (_, getState) => {
  if (message.file && !message.media_url) {
    const aws = getAWS(getState());
    const { url } = await uploadS3({ aws, key: message.id, file: message.file });
    return assoc('media_url', url, message);
  }
  return message;
};

const getLocationData = (() => {
  const loadImage = ImageLoader();
  return async ({
    aws, key, latitude, longitude,
  }) => {
    const data = await parseLocation({ latitude, longitude });
    if (!data?.url) {
      return data;
    }

    const dataURL = await loadImage(data.url);
    const file = fileFromDataURL(dataURL);
    const { key: image } = await uploadS3({ aws, key, file });
    return {
      ...data,
      image,
    };
  };
})();

const processGeolocation = (message) => async (dispatch, getState) => {
  if (
    message.type === LOCATION
    && message.info.data.address === undefined
    && message.info.data.image === undefined
  ) {
    const aws = getAWS(getState());
    const { content } = getMessageContent(message);
    const data = await getLocationData({
      aws,
      key: message.id,
      latitude: content.latitude,
      longitude: content.longitude,
    });
    return {
      ...message,
      info: {
        ...message.info,
        data: {
          ...message.info.data,
          address: data?.address ?? null,
          image: data?.image ?? null,
        },
      },
    };
  }
  return message;
};

const createMessage = ({ conversation, content, state }) => {
  const { environment } = getAppInfo(state);
  const { XMPP_HOST } = Endpoints(environment);

  const rawMessage = {
    ...content,
    host: XMPP_HOST,
    companyId: getSelectedCompanyId(state),
    author: getUserAgentId(state),
  };

  if (conversation.type === AGENT) {
    const { id, agent } = splitConversationId(conversation);
    rawMessage.to = agent;
    rawMessage.toCompanyId = id;
  } else if (conversation.type === GROUP) {
    rawMessage.groupId = conversation.id;
  } else {
    rawMessage.to = conversation.id;
  }

  return newMessage(rawMessage);
};

export const sendMessage = curry((
  conversationId,
  content, // Message || { text, file, location, session }
) => async (dispatch, getState, getContext) => {
  const companyId = getSelectedCompanyId(getState());
  const conversation = getConversation(companyId, conversationId, getState());
  if (!conversation) {
    return;
  }

  let message = isMessage(content)
    ? { ...content }
    : createMessage({ conversation, content, state: getState() });

  const toConversationId = conversationIdFromMessage(message, getState());
  if (conversationId !== toConversationId) {
    return;
  }

  let sendFunction;
  const { api } = getContext();
  if (conversation.type === GROUP) {
    sendFunction = curry(api.messages.sendGroupMessage)(conversation);
  } else {
    sendFunction = api.messages.send;
  }

  message.date = Date.now();
  message.deliveryState = QUEUED;
  dispatch(updateMessages(companyId, conversationId, [message]));

  try {
    await dispatch(lockConversation(conversationId));
    message = await dispatch(uploadMedia(message));
    message = await dispatch(processGeolocation(message));
    await sendFunction(message);
    dispatch(updateMessages(
      companyId,
      conversationId,
      [assoc('deliveryState', SENT, message)],
    ));
  } catch (error) {
    dispatch(updateMessages(
      companyId,
      conversationId,
      [assoc('deliveryState', NOT_SENT, message)],
    ));
  }
});

export const addInfoMessage = (
  conversationId,
  text,
  { twnelAssistant = false } = {},
) => (dispatch, getState) => {
  const companyId = getSelectedCompanyId(getState());
  const conversation = getConversation(companyId, conversationId, getState());
  if (!conversation) {
    return;
  }

  let message;
  if (twnelAssistant) {
    message = newSessionMessage({ text, companyId: 'twnel', session: 'note' });
    message.author = 'Bot - Olivia';
  } else {
    message = newSessionMessage({ companyId, text });
  }
  dispatch(updateMessages(companyId, conversationId, [message]));
};

/**
 * Load broadcast messages, and group messages based on  their tags.
 * @returns {Object.<string, {{tags: string[], messages: {*}[]}}>}
 */
export const loadBroadcastMessages = () => async (dispatch, getState, getContext) => {
  const perPage = 100;
  const { api } = getContext();
  const broadcasts = await (async function loadBroadcasts(page = 1, list = []) {
    const {
      broadcasts: loadedBroadcasts = [],
    } = await api.messages.getBroadcastMessages({ page, perPage })
      .catch(throwNetworkError);
    const newList = [...list, ...loadedBroadcasts];
    return loadedBroadcasts.length < perPage || newList.length >= 500
      ? newList : loadBroadcasts(page + 1, newList);
  }());
  return compose(
    reduce((result, message) => {
      const { tags } = message;
      const id = tagsToId(tags);
      const group = result[id] || {
        id,
        tags,
        messages: [],
      };
      group.messages.push(message);
      return { ...result, [id]: group };
    }, {}),
    sortMessages,
    filter(prop('isBroadcast')),
    map(parseApiMessage),
  )(broadcasts);
};

/**
 * Send a broadcast message
 * @param {string[]} tags - Tags used to segment the message's audience
 * @param {{
 *   text: string,
 *   file: File,
 *   location: {latitude: string, longitude: string},
 * }} content - Content, include at least one
 */
export const sendBroadcastMessage = (
  tags = [],
  content,
) => async (dispatch, getState, getContext) => {
  const payload = {};
  const { text, file, location } = content;
  if (!text && !file && !location) {
    throw new Error('Invalid broadcast message content');
  }

  if (text) {
    payload.message = text;
  }

  if (file) {
    const aws = getAWS(getState());
    const { url } = await uploadS3({ aws, file });
    payload.fileUrl = url;
  }

  if (location?.latitude && location?.longitude) {
    const aws = getAWS(getState());
    const { latitude, longitude } = location;
    const data = await getLocationData({ aws, latitude, longitude });
    payload.location = { latitude, longitude };
    if (data?.address) {
      payload.location.address = data.address;
    }
    if (data?.image) {
      payload.location.image = data.image;
    }
  }

  const { api } = getContext();
  const requester = getUserAgentId(getState());
  return api.messages.sendBroadcastMessage({
    ...payload,
    registered: true,
    tags,
    requester,
  });
};
