import { compose } from 'ramda';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Sidebar, withTwnelContext, getTexts } from '@twnel/web-components';
import { SidebarConnect } from '@twnel/companies-login';
import Body from './components/Body';
import refreshData from './connect/actions';
import {
  makeUseSections, useTabBadge, useOnlineStatus, useDarkMode,
} from './connect/hooks';
import { getShouldRefresh } from './connect/selectors';

const getAvailabilty = ({ toggleOnline, available, autolockig }) => {
  if (available === undefined) {
    return {
      status: Sidebar.STATUS.ACTIVE,
    };
  }
  if (available && autolockig) {
    return {
      status: Sidebar.STATUS.CUSTOM('Autolock'),
      toggleOnline,
    };
  }
  return {
    status: available
      ? Sidebar.STATUS.ACTIVE : Sidebar.STATUS.AWAY,
    toggleOnline,
  };
};

const useNavigate = () => {
  const history = useHistory();
  return {
    goTo: (path) => () => {
      history.push(path);
    },
    isPath: (path) => {
      const { location } = history;
      return location.pathname.startsWith(path);
    },
  };
};

const connect = compose(SidebarConnect, withTwnelContext);
const SidebarContainer = connect(Sidebar);

export default function makeShell(store) {
  const useSections = makeUseSections(store);

  const Shell = () => {
    const dispatch = useDispatch();
    const shouldRefresh = useSelector(getShouldRefresh);
    const dipatchRefreshData = React.useCallback(
      () => dispatch(refreshData()),
      [dispatch],
    );

    const sections = useSections();
    useTabBadge(sections);

    const {
      onlineSince, toggleOnline, available, autolockig,
    } = useOnlineStatus();
    const availability = getAvailabilty({ toggleOnline, available, autolockig });

    const [darkMode, toggleDarkMode] = useDarkMode();

    const texts = useSelector(getTexts);
    const { goTo, isPath } = useNavigate();
    return (
      <SidebarContainer
        darkMode={darkMode}
        toggleDarkMode={toggleDarkMode}
        render={({ width, height, dataIsReady }) => (
          <Body
            width={width}
            height={height}
            sections={sections}
            refreshData={dipatchRefreshData}
            shouldRefresh={dataIsReady && shouldRefresh}
          />
        )}
        options={sections.map(({
          path, icon, alt, badge,
        }) => ({
          icon,
          alt: texts(alt),
          onClick: goTo(path),
          badge,
          active: isPath(path),
        }))}
        onlineSince={onlineSince}
        availability={availability}
        toggleOnline={toggleOnline}
      />
    );
  };

  return Shell;
}
