import { xor } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mergeDefaultTheme as mergeDefault } from '@twnel/web-components';
import { SectionContainer } from './misc';

const CheckContainer = styled(SectionContainer)`
  display: flex;
  align-items: center;
  height: 2.5rem;
  margin: 0.325rem 0 0.325rem 0.325rem;
  cursor: pointer;
  color: ${mergeDefault(({ theme }) => theme.actionable)};
  font-size: 0.9rem;
  font-weight: 500;
  &:last-child {
    margin-bottom: 1.3rem;
  }
  @media (hover: hover) {
    i {
      transition: transform 0.2s ease-out;
    }
    &:hover i, &:active i {
      transform: scale(1.1);
    }
  }
`;

const CheckFilter = ({
  flip, label, value, onChange,
}) => (
  <CheckContainer
    onClick={() => {
      onChange(!value);
    }}
  >
    <div style={{ flexGrow: 1 }}>
      {label}
    </div>
    <div>
      {xor(value, flip) ? (
        <i className="fas fa-check-square" />
      ) : (
        <i className="fas fa-square" />
      )}
    </div>
  </CheckContainer>
);

CheckFilter.propTypes = {
  flip: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

CheckFilter.defaultProps = {
  flip: false,
  label: '',
  value: false,
};

export default CheckFilter;
