import React from 'react';
import { render } from 'react-dom';
import { createStore, combineReducers } from 'redux';
import { resourceReducer, ReducerRegistry, NotificationsManager } from '@twnel/web-components';
import { getApiHost, extendedAgentFields } from '@twnel/utils-js/lib/web';
import { reducer as sessionReducer, LoginEnhancer } from '@twnel/companies-login';
import { fetchTexts } from 'src/data/actions';
import appReducer from 'src/data/reducers';
import makeThunkContext from 'src/data/socket';
import makeApp from 'src/ui/App';

// Env vars
const { npm_package_version: version, TWNEL_ENV, NODE_ENV } = process.env;
const environment = getApiHost() || TWNEL_ENV;
const debug = NODE_ENV !== 'production';

// Register reducers
const reducerRegistry = ReducerRegistry();
reducerRegistry.register({
  ...resourceReducer,
  ...sessionReducer,
  ...appReducer,
});

// Login setup
const notificationsManager = NotificationsManager();
const enhancer = LoginEnhancer({
  version,
  environment,
  thunkContext: (store) => {
    const thunkContext = makeThunkContext(store, environment, debug);
    return (company) => ({
      reducerRegistry,
      notificationsManager,
      ...thunkContext(company),
    });
  },
  options: {
    includeAgentOnlineStatus: true,
    includeAgentAutolockStatus: true,
    includeBusinessUnits: true,
    pickAgentFields: extendedAgentFields,
  },
  debug,
});

// Store setup
const reducer = combineReducers(reducerRegistry.getReducers());
const store = createStore(reducer, enhancer);

// Load the texts file
store.dispatch(fetchTexts());

// Initial render
const App = makeApp(store);
render(
  React.createElement(App),
  document.getElementById('react'),
);
