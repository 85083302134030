import {
  assoc, mergeRight, pluck, mergeDeepRight, mergeDeepLeft, fromPairs, uniq, omit, without,
} from 'ramda';
import { pickExisting } from '@twnel/web-components';
import { CONVERSATION_EXPOSURE } from '@twnel/utils-js/lib/web';
import {
  MESSAGES, UPDATE_CONVERSATIONS, UPDATE_MESSAGES, DELETE_MESSAGES, UPDATE_MESSAGES_INFO,
} from '../constants';

const { DELETED } = CONVERSATION_EXPOSURE;

const messagesList = (state = {
  info: {},
  byId: {},
  allIds: [],
}, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_MESSAGES_INFO: {
      const updates = pickExisting(['complete', 'upToDate', 'nextPageMarker'], payload);
      return assoc('info', mergeRight(state.info, updates), state);
    }
    case UPDATE_MESSAGES: {
      let messageIds;
      const { messages, insert, leftMerge } = payload;
      const pairs = messages.map((message) => [message.id, message]);
      if (insert === 'replace') {
        messageIds = pluck(0, pairs);
      } else if (insert === 'left') {
        messageIds = [...pluck(0, pairs), ...state.allIds];
      } else {
        messageIds = [...state.allIds, ...pluck(0, pairs)];
      }
      return mergeRight(state, {
        byId: (leftMerge ? mergeDeepLeft : mergeDeepRight)(
          state.byId,
          fromPairs(pairs),
        ),
        allIds: uniq(messageIds),
      });
    }
    case DELETE_MESSAGES: {
      const { messages } = payload;
      const deletedIds = messages.map((message) => message.id);
      return {
        ...state,
        byId: omit(deletedIds, state.byId),
        allIds: without(deletedIds, state.allIds),
      };
    }
    default:
      return state;
  }
};

const messages = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_CONVERSATIONS: {
      const { companyId, conversations } = payload;
      const companyList = state[companyId] || {};
      const result = conversations.reduce(([updates, deleted], conversation) => {
        if (conversation.exposure === DELETED) {
          return [updates, [...deleted, conversation.id]];
        }
        const { id, lastMessage } = conversation;
        if (!lastMessage) {
          return [updates, deleted];
        }
        return [{
          ...updates,
          [id]: messagesList(companyList[id], {
            type: UPDATE_MESSAGES,
            payload: {
              messages: [lastMessage],
              insert: 'right',
              leftMerge: true,
            },
          }),
        }, deleted];
      }, [{}, []]);
      return assoc(companyId, omit(result[1], { ...companyList, ...result[0] }), state);
    }
    case UPDATE_MESSAGES_INFO:
    case UPDATE_MESSAGES:
    case DELETE_MESSAGES: {
      const { companyId, conversationId } = payload;
      const companyList = state[companyId] || {};
      const updatedList = assoc(
        conversationId,
        messagesList(companyList[conversationId], action),
        companyList,
      );
      return assoc(companyId, updatedList, state);
    }
    default:
      return state;
  }
};

export default {
  [MESSAGES]: messages,
};
