import { identity, equals } from 'ramda';
import React from 'react';
import Fuse from 'fuse.js';
import { useSelector, useDispatch } from 'react-redux';
import {
  tryUntilResolve, rejectClientErrors, useListener, useTheme, getTexts,
} from '@twnel/web-components';
import { getServiceTime } from '../selectors';

const splitDate = (timestamp) => {
  if (!Number.isInteger(timestamp)) {
    return {};
  }
  const time = Math.max(0, Date.now() - timestamp);
  const seconds = Math.round(time * 0.001);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const years = Math.floor(days / 365);
  if (years > 0) {
    return { years };
  }
  if (days > 0) {
    return {
      days,
      interval: 12 * 60 * 60 * 1000,
    };
  }
  if (hours > 0) {
    return {
      hours,
      interval: 30 * 60 * 1000,
    };
  }
  return {
    minutes,
    interval: 30 * 1000,
  };
};

export const useIntervalFilter = ({ list = [], filter = identity, interval = 2500 }) => {
  const filterRef = useListener(filter);
  const [update, setUpdate] = React.useState(1);

  React.useEffect(() => {
    const timeout = setInterval(() => {
      setUpdate((value) => (value % 10) + 1);
    }, interval);
    return () => {
      clearInterval(timeout);
    };
  }, [interval]);

  const filteredList = React.useMemo(
    () => update && list.filter(filterRef),
    [update, list, filterRef],
  );

  return filteredList;
};

export const useShortDate = (timestamp) => {
  const texts = useSelector(getTexts);
  const [date, setDate] = React.useState({});

  React.useEffect(() => {
    setDate(splitDate(timestamp));
  }, [timestamp]);

  React.useEffect(() => {
    if (!date.interval) {
      return undefined;
    }
    const intervalReference = setInterval(() => {
      setDate(splitDate(timestamp));
    }, date.interval);
    return () => clearInterval(intervalReference);
  }, [timestamp, date.interval]);

  return React.useMemo(() => {
    if (date.years) {
      return `${date.years}${texts('y')}`;
    }
    if (date.days) {
      return `${date.days}${texts('d')}`;
    }
    if (date.hours) {
      return `${date.hours}${texts('h')}`;
    }
    if (date.minutes) {
      return `${date.minutes}${texts('m')}`;
    }
    if (date.minutes === 0) {
      return texts('now');
    }
    return null;
  }, [date.minutes, date.hours, date.days, date.years, texts]);
};

const serviceTimesColors = (theme) => ({
  good: theme.success,
  regular: theme.warning,
  bad: theme.error,
});

export const useServiceTimeDate = () => {
  const theme = useTheme();
  const serviceTime = useSelector(getServiceTime);
  const getColor = React.useCallback((timeStamp) => {
    const colors = serviceTimesColors(theme);
    const time = (Date.now() - timeStamp) / (60 * 1000);
    if (time <= serviceTime) {
      return colors.good;
    }
    if (time <= serviceTime * 3.0) {
      return colors.regular;
    }
    return colors.bad;
  }, [theme, serviceTime]);
  return getColor;
};

const startAnimation = ({
  forward, easing, offset, interval, setOffset,
}) => {
  let initialStamp;
  let animating = true;
  const anchor = forward ? 1 - offset : offset;
  const frame = (stamp) => {
    if (!animating) {
      return;
    }
    if (!initialStamp) {
      initialStamp = stamp;
    }
    const progress = Math.min(stamp - initialStamp, interval);
    const increase = easing(progress / interval) * anchor * (forward ? 1 : -1);
    setOffset(offset + increase);
    if (progress < interval) {
      requestAnimationFrame(frame);
    }
  };
  requestAnimationFrame(frame);
  return {
    forward,
    cancel: () => {
      animating = false;
    },
  };
};

export const useAnimation = (forward, {
  interval = 250,
  easing = (x) => (x - 1) ** 3 + 1,
} = {}) => {
  const animation = React.useRef(null);
  const [start, setStart] = React.useState(false);
  const [offset, setOffset] = React.useState(+forward);

  React.useEffect(() => {
    if (forward !== !!(animation.current?.forward)) {
      setStart(true);
    }
    return () => {
      // eslint-disable-next-line no-unused-expressions
      animation.current?.cancel();
    };
  }, [forward]);

  if (start) {
    animation.current = startAnimation({
      forward, easing, offset, interval, setOffset,
    });
    setStart(false);
  }

  return offset;
};

export const useFuzzySearch = (items, searchKeys, options = {}) => {
  const keysRef = React.useRef(searchKeys);
  if (!equals(keysRef.current, searchKeys)) {
    keysRef.current = searchKeys;
  }

  const optionsRef = React.useRef(options);
  if (!equals(optionsRef.current, options)) {
    optionsRef.current = optionsRef;
  }

  return React.useMemo(() => {
    const fuse = new Fuse(items, {
      includeScore: true,
      threshold: 0.4,
      ...optionsRef.current,
      keys: keysRef.current,
    });
    return (query) => {
      if (!query) {
        return items.map((item) => ({ item }));
      }
      return fuse.search(query);
    };
  }, [items]);
};

export const useLoopDispatch = () => {
  const dispatch = useDispatch();
  const idRef = React.useRef(null);
  return React.useCallback((id, action) => {
    idRef.current = id;
    const loop = tryUntilResolve(
      action,
      (error) => idRef.current === id && rejectClientErrors(error),
    );
    return dispatch(loop);
  }, [dispatch]);
};
