import { always, prop } from 'ramda';
import { createSelector } from 'reselect';
import { getTexts, uncurrySelector } from '@twnel/web-components';
import {
  splitConversationId, newConversation, isSuperAdmin, isAdmin,
  Endpoints, CONVERSATION_TYPE, CONVERSATION_EXPOSURE,
} from '@twnel/utils-js/lib/web';
import {
  getAppInfo, getSelectedCompanyId, getUserAgentId, getUserAgent, getCompany,
  getAllBusinessUnits, getAgent,
} from '@twnel/companies-login';
import { isAnonymous, filterBusinessUnits } from 'src/data/actions';
import {
  getConversation, getContact, getConversationsAreLoading, getCleanUserProfiles, getLastMessage,
  getIsAutolockerEnabled,
} from 'src/data/selectors';
import {
  conversationAvatar, messageAvatar, agentAvatar, messageSummary,
} from 'src/data/util';

const AUTOLOCKER_WINDOW = 20 * 1000; // 20s

const {
  AGENT, BUSINESS, CUSTOMER, GROUP,
} = CONVERSATION_TYPE;
const { PENDING, ACTIVE, INACTIVE } = CONVERSATION_EXPOSURE;

export const getSelectedCompany = getCompany(getSelectedCompanyId);

export const getBusinessUnits = getAllBusinessUnits(getSelectedCompanyId);

export const getAgentIsAdmin = createSelector(getUserAgent, isAdmin);

export const getAgentIsSuperAdmin = createSelector(getUserAgent, isSuperAdmin);

export const getConversationsLoading = getConversationsAreLoading(getSelectedCompanyId);

export const getServiceTime = createSelector(
  getCompany(getSelectedCompanyId),
  ({ service_time: serviceTime }) => (serviceTime ? serviceTime / 60 : 3.0),
);

export const getHeaderAvatar = (state) => (conversation) => {
  const avatarProps = conversationAvatar(conversation, state);
  if (avatarProps.avatarType === 'agent') {
    const selectedCompanyId = getSelectedCompanyId(state);
    const agent = getAgent(avatarProps.companyId, avatarProps.id, state);
    if (!agent?.online) {
      avatarProps.online = -1;
    } else if (selectedCompanyId === avatarProps.companyId) {
      avatarProps.online = agent?.autolock === false ? 0 : 1;
    } else {
      avatarProps.online = 1;
    }
  } else {
    avatarProps.online = undefined;
  }
  if (conversation.agent?.id) {
    const agent = agentAvatar(getSelectedCompanyId, conversation.agent.id, state);
    avatarProps.assignedAgent = agent;
  }
  return avatarProps;
};

const messageDescription = (conversation, message, state) => {
  let author;
  const texts = getTexts(state);
  const { author: messageAuthor } = message;
  const { id, agent } = splitConversationId(conversation);
  if ((agent || id) !== messageAuthor) {
    const userAgentId = getUserAgentId(state);
    if (messageAuthor === userAgentId) {
      author = texts('You');
    } else {
      const { name } = messageAvatar(message, state);
      author = name;
    }
  }
  return {
    ...messageSummary(message, texts),
    author,
  };
};

const parseConversationTags = (conversation, state) => {
  const businessUnits = getAllBusinessUnits(getSelectedCompanyId, state);
  const filteredBusinessUnits = filterBusinessUnits(
    conversation,
    businessUnits.filter(({ tags }) => tags.length),
  );
  if (filteredBusinessUnits.length) {
    return {
      tags: filteredBusinessUnits.map(prop('name')),
      highlightTags: true,
    };
  }
  if (conversation.tags.length) {
    return {
      tags: conversation.tags,
    };
  }
  const texts = getTexts(state);
  return {
    empty: texts('No tags assigned'),
  };
};

export const parseConversation = (state) => (conversation) => {
  const result = conversationAvatar(conversation, state);

  const { type, exposure, badge } = conversation;
  if (type === GROUP || type === BUSINESS) {
    return {
      ...result,
      ...parseConversationTags(conversation, state),
      badge,
    };
  }

  const { id: withId } = splitConversationId(conversation);
  const selectedCompanyId = getSelectedCompanyId(state);
  if (exposure === ACTIVE || (type === AGENT && withId === selectedCompanyId)) {
    const { id } = conversation;
    const lastMessage = getLastMessage(getSelectedCompanyId, id, state);
    return {
      ...result,
      message: lastMessage ? messageDescription(conversation, lastMessage, state) : undefined,
      date: lastMessage?.date,
      badge,
    };
  }

  if (exposure === PENDING) {
    const { pending_at: date } = conversation;
    result.date = date;
    result.highlightDate = true;
    result.badge = true;
    if (isAnonymous(conversation)) {
      const texts = getTexts(state);
      return {
        ...result,
        empty: texts('Unregistered'),
      };
    }
    return {
      ...result,
      ...parseConversationTags(conversation, state),
    };
  }

  if (exposure === INACTIVE) {
    const { closed_at: date, topic } = conversation;
    result.date = date;
    if (!topic) {
      const texts = getTexts(state);
      return {
        ...result,
        empty: texts('Not classified'),
      };
    }
    return {
      ...result,
      topic: topic.name ?? topic.id,
    };
  }

  return result;
};

export const getTemplates = createSelector(
  getUserAgent,
  ({ templates = {} }) => templates,
);

export const getXmppHost = createSelector(
  getAppInfo,
  ({ environment } = {}) => {
    const { XMPP_HOST } = Endpoints(environment);
    return XMPP_HOST;
  },
);

export const getAvailableConversation = uncurrySelector(2, (model) => createSelector(
  getXmppHost,
  getSelectedCompanyId,
  getCleanUserProfiles,
  getContact(getSelectedCompanyId, model.id),
  (host, selectedCompanyId, profiles, conversation) => {
    if (conversation) {
      return conversation;
    }
    const temporalConversation = newConversation(model, { host, owner: selectedCompanyId });
    return profiles[model.id] ? {
      ...temporalConversation,
      ...profiles[model.id],
    } : temporalConversation;
  },
));

export const getAvailableAgentConversation = uncurrySelector(
  2,
  ({ agentId, companyId }) => (state) => {
    const selectedCompanyId = getSelectedCompanyId(state);
    const userAgentId = getUserAgentId(state);
    if ((!companyId || selectedCompanyId === companyId) && userAgentId === agentId) {
      return null;
    }
    const host = getXmppHost(state);
    const company = getCompany(companyId || selectedCompanyId, state);
    const conversation = newConversation(company, {
      agent: agentId,
      owner: selectedCompanyId,
      resource: userAgentId,
      host,
    });
    return getConversation(selectedCompanyId, conversation.id, state) || conversation;
  },
);

/**
 * B2C conversations that have been pending for an interval less than the
 * AUTOLOCK_WINDOW are assumed to be in the process of being auto-locked.
 * This selector returns a filter that returns true if the given conversation
 * is currently in the process of being auto-locked.
 * @param Object - state
 * @return (Conversation) -> Bool
 */
export const getAutolockerFilter = createSelector(
  getIsAutolockerEnabled,
  (autolockEnabled) => (autolockEnabled ? ({ exposure, type, pending_at: pendingAt }) => (
    exposure === PENDING
    && type === CUSTOMER
    && pendingAt && (Date.now() - pendingAt <= AUTOLOCKER_WINDOW)
  ) : always(false)),
);
