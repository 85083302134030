import {
  map, prop, compose, head,
} from 'ramda';
import { createSelector } from 'reselect';
import { smartSelector, uncurrySelector } from '@twnel/web-components';
import { isMetaMessage, sortMessages } from '@twnel/utils-js/lib/web';
import { getSelectedCompanyId, getUserAgentId } from '@twnel/companies-login';
import { MESSAGES } from '../constants';

const getMessages = (state) => state[MESSAGES];

const getAllMessagesObjects = (companyId) => smartSelector(
  companyId,
  getMessages,
  (company, messages) => messages[company] || {},
);

const getMessagesObject = (companyId, conversationId) => smartSelector(
  conversationId,
  getAllMessagesObjects(companyId),
  prop,
);

// companyId -> state -> messagesInfos
export const getAllMessagesInfo = uncurrySelector(2, (companyId) => createSelector(
  getAllMessagesObjects(companyId),
  map(prop('info')),
));

// companyId -> conversationId -> state -> info
export const getMessagesInfo = uncurrySelector(
  3,
  (companyId) => (conversationId) => createSelector(
    getMessagesObject(companyId, conversationId),
    ({ info } = {}) => info || {},
  ),
);

// companyId -> conversationId -> state -> messages
export const getAllMessages = uncurrySelector(
  3,
  (companyId) => (conversationId) => createSelector(
    getMessagesObject(companyId, conversationId),
    ({ byId = {}, allIds = [] } = {}) => compose(
      sortMessages,
      map((id) => byId[id]),
    )(allIds),
  ),
);

// companyId -> conversationId -> messageId -> state -> message
export const getMessage = uncurrySelector(
  4,
  (companyId) => (conversationId) => (messageId) => createSelector(
    getMessagesObject(companyId, conversationId),
    ({ byId = {} } = {}) => byId[messageId],
  ),
);

// companyId -> conversationId -> state -> message
export const getTopMessage = uncurrySelector(
  3,
  (companyId) => (conversationId) => createSelector(
    getAllMessages(companyId, conversationId),
    head,
  ),
);

// companyId -> conversationId -> state -> message
export const getLastMessage = uncurrySelector(
  3,
  (companyId) => (conversationId) => createSelector(
    getAllMessages(companyId, conversationId),
    (messages) => {
      const getLast = (index) => {
        const message = messages[index];
        return isMetaMessage(message) ? undefined : message;
      };
      let i = messages.length - 1;
      let lastMessage = getLast(i);
      while (i > 0 && !lastMessage) {
        i -= 1;
        lastMessage = getLast(i);
      }
      return lastMessage;
    },
  ),
);

// companyId -> conversationId -> state -> message
export const getLastReadMessage = uncurrySelector(
  3,
  (companyId) => (conversationId) => createSelector(
    getSelectedCompanyId,
    getUserAgentId,
    getAllMessages(companyId, conversationId),
    (selectedCompanyId, userAgentId, messages) => {
      const getLast = (index) => {
        const message = messages[index];
        return isMetaMessage(message) || (
          message?.companyId === selectedCompanyId && message?.author === userAgentId
        ) ? undefined : message;
      };
      let i = messages.length - 1;
      let lastMessage = getLast(i);
      while (i > 0 && !lastMessage) {
        i -= 1;
        lastMessage = getLast(i);
      }
      return lastMessage;
    },
  ),
);
