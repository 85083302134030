import {
  compose, evolve, pick, equals,
} from 'ramda';
import Socket, { SOCKET_EVENT, SOCKET_STATE } from '@twnel/utils-js/lib/socket';
import { Api, Http, Endpoints } from '@twnel/utils-js/lib/web';
import {
  onCompanyUpdate, onBusinessUnitUpdate, onChatbotUpdate, onAgentUpdate, onAgentAutolockerUpdate,
  onSocketConversations, onTypingEvent, onSocketMessages, onSocketConnection, onSocketOverride,
  onTokenExpired,
} from 'src/data/actions';
import { logger } from 'src/data/util';

const { CONNECTION, OVERRIDE } = SOCKET_EVENT;
const { ONLINE } = SOCKET_STATE;

const batchEvents = (
  listener,
  { delay = 300, maxDelay = 1000 } = {},
) => {
  let queue = {
    payload: [],
    count: 0,
  };

  const clearQueue = () => {
    if (
      (queue.payload.length - queue.count <= 1)
      || (Date.now() - queue.timestamp > maxDelay)
    ) {
      listener(queue.payload);
      queue = {
        payload: [],
        count: 0,
      };
    } else {
      queue.count = queue.payload.length;
      queue.timeout = setTimeout(clearQueue, delay);
    }
  };

  return (newPayload) => {
    queue.payload.push(newPayload);
    if (!queue.timeout) {
      queue.timestamp = Date.now();
      queue.timeout = setTimeout(clearQueue, delay);
    }
  };
};

const onModelUpdate = (event) => ({ model, payload }) => (dispatch) => {
  switch (model) {
    case 'agent':
      dispatch(onAgentUpdate({ event, payload }));
      break;
    case 'company':
      dispatch(onCompanyUpdate({ event, payload }));
      break;
    case 'unit':
      dispatch(onBusinessUnitUpdate({ event, payload }));
      break;
    case 'chatbot':
      dispatch(onChatbotUpdate({ event, payload }));
      break;
    case 'autolocker:agent':
      dispatch(onAgentAutolockerUpdate({ event, payload }));
      break;
    default:
      break;
  }
};

const makeSocket = ({ dispatch }, debug) => {
  const socket = Socket({ debug });
  socket.setLogger(logger({ debug, verbose: true }));

  // Socket connection
  socket.on(CONNECTION, compose(dispatch, onSocketConnection, equals(ONLINE)));
  socket.on(OVERRIDE, compose(dispatch, onSocketOverride));

  // Chat traffic
  const messageListener = batchEvents(compose(dispatch, onSocketMessages));
  socket.on('message', messageListener);
  socket.on('room-message', messageListener);
  socket.on('conversation', batchEvents(compose(dispatch, onSocketConversations)));
  socket.on('event', (data) => {
    const { event: eventType } = data || {};
    if (eventType === 'typing') {
      dispatch(onTypingEvent(data.from));
    }
  });

  // Model updates
  socket.on('updated', compose(dispatch, onModelUpdate('update')));
  socket.on('deleted', compose(dispatch, onModelUpdate('delete')));
  socket.on('created', compose(dispatch, onModelUpdate('create')));
  socket.on('online', compose(
    dispatch,
    onModelUpdate('update'),
    evolve({
      payload: (id) => ({ id, online: true }),
    }),
  ));
  socket.on('offline', compose(
    dispatch,
    onModelUpdate('update'),
    evolve({
      payload: (id) => ({ id, online: false }),
    }),
  ));
  socket.on('availability', compose(
    dispatch,
    onModelUpdate('update'),
    ({ agent, available } = {}) => ({
      model: 'autolocker:agent',
      payload: { id: agent, autolock: available },
    }),
  ));

  return socket;
};

const makeThunkContext = ({ dispatch }, endpoints, debug) => {
  let socket;
  return (credentials = {}) => {
    const { userAgent, apikey, token } = credentials;
    if (!userAgent || (!apikey && !token)) {
      return {};
    }
    if (!socket) {
      socket = makeSocket({ dispatch }, debug);
    }

    const http = Http({
      apikey,
      token,
      onTokenExpire: compose(dispatch, onTokenExpired),
      requester: userAgent,
      socket,
    });
    return { api: Api(endpoints, http), socket };
  };
};

export default function (store, environment, debug) {
  let credentials = {};
  const endpoints = Endpoints(environment);
  const thunkContext = makeThunkContext(store, endpoints, debug);
  return (company) => {
    const newCredentials = pick(['userAgent', 'apikey', 'token'], company || {});
    const result = thunkContext(newCredentials);
    if (result.socket && !equals(credentials, newCredentials)) {
      const { SOCKET_HOST } = endpoints;
      const { userAgent, apikey, token } = newCredentials;
      result.socket.connect(SOCKET_HOST, { apikey, token }, userAgent);
    }
    credentials = newCredentials;
    return result;
  };
}
